@import url(https://rsms.me/inter/inter.css);
body,
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

.DateRangePicker_picker__portal {
  z-index: 12 !important;
}
.DateRangePicker_picker__portal .CalendarDay__selected,
.DateRangePicker_picker__portal .CalendarDay__selected:active,
.DateRangePicker_picker__portal .CalendarDay__selected:hover {
  background: #15198f;
  border: 1px double #15198f;
}
.DateRangePicker_picker__portal .CalendarDay__selected_span {
  background: #4785ff;
  border: 1px double #3a7cff;
}
.DateRangePicker_picker__portal .CalendarDay__hovered_span,
.DateRangePicker_picker__portal .CalendarDay__hovered_span:active,
.DateRangePicker_picker__portal .CalendarDay__hovered_span:hover {
  background: #4785ff;
  border: 1px double #3a7cff;
  color: #fff;
}

@media only screen and (max-width: 992px) {
  #purechat-container {
    display: none;
  }
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
body,
html {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
.navbar-desktop {
  position: relative;
  z-index: 11;
}
.navbar-desktop header {
  display: inline-flex;
  width: 100%;
  height: 130px;
}
.navbar-desktop header a.logo {
  display: flex;
  width: 240px;
}
.navbar-desktop header .nav__items {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.navbar-desktop header .nav__items a {
  color: #0f1540;
  padding: 0.75rem 0.3rem;
  margin: 0 0.3rem;
  text-decoration: none;
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
}
.navbar-desktop header .nav__items a:hover:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0.4rem;
  background: #e13505;
  width: 100%;
  height: 3px;
}
.navbar-desktop header .info {
  position: relative;
}
.navbar-desktop header .info:hover .info--dropdown {
  display: block;
}
.navbar-desktop header .info--dropdown {
  display: none;
  position: absolute;
  top: 150%;
  right: 0;
  background: #fff;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
}
.navbar-desktop header .info--dropdown ul {
  margin: 0;
  padding: 0;
}
.navbar-desktop header .info--dropdown ul li {
  list-style: none;
  transition: 0.15s all ease;
  color: #191919;
}
.navbar-desktop header .info--dropdown ul li a {
  display: inline-block;
  padding: 0.7rem;
  padding-right: 2rem;
  font-size: 0.85rem;
  font-weight: 500;
  margin: 0;
  background: none;
  color: #191919;
  text-transform: none;
  width: 100%;
  border-radius: 0;
  transition: 0.15s all ease;
}
.navbar-desktop header .info--dropdown ul li a:hover {
  transition: 0.15s all ease;
  background: #f9f9f9;
  text-decoration: none;
}
.navbar-desktop header .info--dropdown ul li a:hover:before {
  display: none;
}
.navbar-desktop header .info--dropdown ul li:hover {
  cursor: pointer;
}
.navbar-desktop header .nav__actions {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}
.navbar-desktop header .nav__actions a {
  text-decoration: none;
  font-weight: 700;
  padding: 0.5rem 2rem;
  border-radius: 3rem;
  margin: 0 0.25rem;
  font-size: 0.85rem;
  text-transform: uppercase;
}
.navbar-desktop header .nav__actions a:first-child {
  border: 2px solid #262a68;
  color: #262a68;
  transition: 0.25s all ease;
}
.navbar-desktop header .nav__actions a:first-child:hover {
  transition: 0.25s all ease;
  color: #fff;
  background: #262a68;
}
.navbar-desktop header .nav__actions a:last-child {
  color: #fff;
  background: #00b27e;
  border: 2px solid #00b27e;
  padding: 0.5rem 1.15rem;
  transition: 0.25s all ease;
}
.navbar-desktop header .nav__actions a:last-child:hover {
  transition: 0.25s all ease;
  background: #07ce94;
  border-color: #07ce94;
}
.navbar-desktop header .nav__actions .nav__logged_actions {
  position: relative;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
}
.navbar-desktop header .nav__actions .nav__logged_actions img {
  width: 45px;
  min-width: 45px;
  max-width: 45px;
  height: 45px;
  min-height: 45px;
  max-height: 45px;
}
.navbar-desktop header .nav__actions .nav__logged_actions a {
  border: none;
  padding: 0;
  background: none;
}
.navbar-desktop header .nav__actions .nav__logged_actions a:hover {
  color: unset;
  background: unset;
}
.navbar-desktop header .nav__actions .nav__logged_actions .email {
  position: relative;
}
.navbar-desktop header .nav__actions .nav__logged_actions .email .email--count {
  position: absolute;
  right: 0;
  bottom: 0;
}
.navbar-desktop header .nav__actions .nav__logged_actions .email .email--count h6 {
  margin: 0;
  padding: 0.25rem 0.5rem;
  background: #e13505;
  color: #fff;
  border-radius: 2rem;
  font-size: 0.7rem;
}
.navbar-desktop header .nav__actions .nav__logged_actions .email,
.navbar-desktop header .nav__actions .nav__logged_actions .user {
  margin: 0 0.25rem;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user {
  width: 100%;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user:hover .user--dropdown {
  display: block;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user--dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
}
.navbar-desktop header .nav__actions .nav__logged_actions .user--dropdown ul {
  margin: 0;
  padding: 0;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user--dropdown ul li {
  list-style: none;
  transition: 0.15s all ease;
  color: #191919;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user--dropdown ul li a {
  display: inline-block;
  padding: 0.7rem;
  padding-right: 2rem;
  font-size: 0.85rem;
  font-weight: 500;
  margin: 0;
  background: none;
  color: #191919;
  text-transform: none;
  width: 100%;
  border-radius: 0;
  transition: 0.15s all ease;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user--dropdown ul li a:hover {
  transition: 0.15s all ease;
  background: #f9f9f9;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user--dropdown ul li:last-child {
  border-top: 1px solid #e8e8e8;
  padding: 0.7rem;
  font-size: 0.85rem;
  font-weight: 500;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user--dropdown ul li:last-child:hover {
  transition: 0.15s all ease;
  background: #f9f9f9;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user--dropdown ul li:hover {
  cursor: pointer;
}
.navbar-mobile {
  background: #f9f9f9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
}
.navbar-mobile header {
  display: inline-flex;
  width: 100%;
  height: 60px;
  position: relative;
}
.navbar-mobile header a.logo {
  display: flex;
}
.navbar-mobile header a.logo img {
  width: 120px;
}
.navbar-mobile header .burger {
  width: 35px;
  height: 18px;
  position: absolute;
  right: 0;
  top: 1.25rem;
}
.navbar-mobile header .burger .burger-inner {
  background: #262a68;
  width: 100%;
  height: 2.5px;
  position: relative;
}
.navbar-mobile header .burger .burger-inner:before,
.navbar-mobile header .burger .burger-inner:after {
  content: "";
  position: absolute;
  background: #262a68;
  width: 100%;
  height: 2.5px;
}
.navbar-mobile header .burger .burger-inner:before {
  top: 10px;
}
.navbar-mobile header .burger .burger-inner:after {
  top: 20px;
}
.navbar-mobile header .nav__items {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5rem 4rem 2rem 3rem;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
}
.navbar-mobile header .nav__items.off {
  transition: 0.25s all ease;
  opacity: 0;
  visibility: hidden;
}
.navbar-mobile header .nav__items.on {
  transition: 0.25s all ease;
  opacity: 1;
  visibility: visible;
}
.navbar-mobile header .nav__items a {
  text-decoration: none;
  color: #0f1540;
  font-weight: 600;
  padding: 0.5rem 0;
}
.navbar-mobile header .nav__items .nav__actions {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
}
.navbar-mobile header .nav__items .nav__actions a {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0.75rem 0;
  border-radius: 2rem;
  font-weight: 700;
}
.navbar-mobile header .nav__items .nav__actions a:first-child {
  background: #00b27e;
  border: 2px solid #00b27e;
  color: #fff;
}
.navbar-mobile header .nav__items .nav__actions a:last-child {
  border: 2px solid #262a68;
  color: #0f1540;
}
.navbar-mobile header .nav__items .nav__actions .nav__logged_actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid #e8e8e8;
  padding-top: 0.75rem;
  margin-top: 0.25rem;
}
.navbar-mobile header .nav__items .nav__actions .nav__logged_actions a {
  background: none;
  border: none;
  color: #0f1540;
  margin: 0;
  padding: 0.75rem;
  display: inline-block;
  width: 100%;
  text-align: left;
  padding-left: 0;
  text-transform: none;
  font-size: 1rem;
  font-weight: 600;
}
.navbar-mobile header .nav__items .close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.navbar-mobile header .nav__items .close img {
  width: 20px;
  height: 20px;
}
@media (max-width: 992px) {
  .navbar-mobile {
    display: block;
  }
  .navbar-desktop {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-desktop {
    display: block;
  }
  .navbar-mobile {
    display: none;
  }
}
@media (max-width: 1140px) and (min-width: 992px) {
  .navbar-desktop header .nav__items a {
    font-size: 14px;
  }
}

.Login {
  display: flex;
  width: 100%;
  margin-top: 64px;
  background: #ffffff;
  background: linear-gradient(0deg, #ffffff 0%, #f4f8fc 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f4f8fc", GradientType=1);
}
.Login .Login--inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 2rem;
}
.Login .Login--inner h2 {
  color: #262a68;
  font-size: 2rem;
  margin-bottom: 0;
}
.Login .Login--inner h4 {
  color: #5d5d5d;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-weight: 400;
}
.Login .Login--inner form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 7.5% 10%;
}
.Login .Login--inner form .floating-label {
  width: 100%;
  position: relative;
}
.Login .Login--inner form .floating-label input {
  display: flex;
  width: 100%;
  padding: 1rem;
  padding-top: 1.35rem;
  font-family: "Inter", sans-serif;
  background: #fff;
  border: 1px solid #ececec;
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #000;
  transition: 0.25s all ease;
}
.Login .Login--inner form .floating-label input:not(:-ms-input-placeholder) ~ label {
  top: 0.7rem;
  left: 1rem;
  font-size: 12px;
  -ms-transition: 0.25s all ease;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Login .Login--inner form .floating-label input:focus ~ label,
.Login .Login--inner form .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.7rem;
  left: 1rem;
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Login .Login--inner form .floating-label input:focus {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.Login .Login--inner form .floating-label label {
  color: #9a9a9a;
  transition: 0.25s all ease;
  position: absolute;
  left: 1rem;
  top: 1.6rem;
  pointer-events: none;
}
.Login .Login--inner form .inline-label {
  display: inline-flex;
  white-space: nowrap;
}
.Login .Login--inner form .inline-label label {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Login .Login--inner form .inline-label input[type="checkbox"] {
  position: relative;
  transform: translateX(-4px);
  cursor: pointer;
}
.Login .Login--inner form .inline-label input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 15.5px;
  height: 15px;
  top: 0;
  left: 0;
  border: 1.5px solid #000;
  border-radius: 3px;
  background-color: #fff;
}
.Login .Login--inner form .inline-label input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 1.5px;
  left: 5.25px;
}
.Login .Login--inner form a {
  color: #262a68;
  text-decoration: none;
  font-size: 14px;
  margin-left: auto;
  text-align: right;
  outline: none;
  padding: 2px;
  transition: 0.25s all ease;
}
.Login .Login--inner form a:hover,
.Login .Login--inner form a:focus {
  color: #000;
  transition: 0.25s all ease;
}
.Login .Login--inner form .Submit__Form {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.Login .Login--inner form .Submit__Form input[type="submit"] {
  font-family: "Inter", sans-serif;
  background: #000;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 0.75rem 4rem;
  border-radius: 10rem;
  text-transform: uppercase;
  margin-left: auto;
  margin-top: 2rem;
  transition: 0.25s all ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.Login .Login--inner form .Submit__Form input[type="submit"]:focus {
  box-shadow: 0 0 0 3px #a0d1fa;
  outline: none;
  transition: 0.25s all ease;
}
.Login .Login--inner form .Submit__Form input[type="submit"]:hover {
  transition: 0.25s all ease;
  background: #272727;
  cursor: pointer;
}
.Login .Login--inner form .Submit__Form input[type="submit"].true {
  background: #000;
  color: #000;
}
.Login .Login--inner form .Submit__Form .loader {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Login .Login--inner .SignUp {
  display: inline-flex;
  align-items: center;
  padding: 0 10%;
  width: 100%;
  flex-direction: column;
}
.Login .Login--inner .SignUp h5 {
  margin: 0;
  color: #535353;
  margin-bottom: 0.25rem;
  font-weight: 400;
  font-size: 1rem;
}
.Login .Login--inner .SignUp a {
  color: #00b27e;
  text-decoration: none;
  font-weight: 500;
  padding: 2px;
  transition: 0.25s all ease;
}
.Login .Login--inner .SignUp a:hover,
.Login .Login--inner .SignUp a:focus {
  transition: 0.25s all ease;
  color: #07ce94;
}
.Login .Login--inner .Login__Error {
  opacity: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Login .Login--inner .Login__Error p {
  transition: 0.25s all ease;
  margin-top: 0;
  margin-bottom: 0.25rem;
  color: #d40000;
  font-weight: 500;
  font-size: 0.9rem;
}
.Login .Login--inner .Login__Error.true {
  opacity: 1;
  transition: 0.25s all ease;
  -webkit-animation-name: true;
  animation-name: true;
}
.Login .Login__Image {
  display: none;
}
@media (min-width: 768px) {
  .Login .Login--inner {
    align-items: flex-start;
  }
  .Login .Login--inner h2,
  .Login .Login--inner h4 {
    padding-left: 25%;
  }
  .Login .Login--inner h2 {
    font-size: 3rem;
  }
  .Login .Login--inner h4 {
    text-align: left;
  }
  .Login .Login--inner form {
    padding: 7.5% 25%;
  }
  .Login .Login--inner form input[type="submit"] {
    margin-right: auto;
  }
  .Login .Login--inner .SignUp {
    padding: 0 25%;
  }
}
@media (min-width: 992px) {
  .Login {
    margin-top: 0;
    max-height: calc(100vh - 134px);
  }
  .Login .Login--inner {
    margin-top: 6rem;
  }
  .Login .Login--inner form {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
  }
  .Login .Login--inner form input[type="submit"] {
    margin-left: unset;
  }
  .Login .Login--inner .SignUp {
    flex-direction: row;
  }
  .Login .Login--inner .SignUp h5 {
    margin: 0;
    margin-right: 0.25rem;
  }
  .Login .Login__Image {
    display: block;
    width: 75%;
    position: relative;
  }
  .Login .Login__Image img:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
  .Login .Login__Image img:last-child {
    width: 100%;
    object-fit: cover;
    max-height: calc(100vh - 134px);
  }
}
@media (min-width: 1200px) {
  .Login .Login--inner {
    margin-top: 8rem;
  }
  .Login .Login--inner h2,
  .Login .Login--inner h4 {
    padding-left: 30%;
  }
  .Login .Login--inner form {
    padding: 2.5% 30%;
  }
  .Login .Login--inner .SignUp {
    padding: 0 30%;
  }
}
@media (min-width: 1400px) {
  .Login .Login--inner h2,
  .Login .Login--inner h4 {
    padding-left: 30%;
  }
  .Login .Login--inner form {
    padding: 2.5% 40% 2.5% 30%;
  }
  .Login .Login--inner .SignUp {
    padding: 0 40% 0 30%;
  }
}
.animated {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes true {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes true {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 16px auto;
  position: relative;
  font-size: 3px;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.loader.false {
  display: none;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@supports (-ms-ime-align: auto) {
  .Login .Login--inner form .floating-label label {
    position: absolute !important;
    top: 0.75rem !important;
    font-size: 12px;
  }
}

.Login {
  display: flex;
  width: 100%;
  margin-top: 64px;
  background: #ffffff;
  background: linear-gradient(0deg, #ffffff 0%, #f4f8fc 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f4f8fc", GradientType=1);
}
.Login .Login--inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 2rem;
}
.Login .Login--inner h2 {
  color: #262a68;
  font-size: 2rem;
  margin-bottom: 0;
}
.Login .Login--inner h4 {
  color: #5d5d5d;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-weight: 400;
}
.Login .Login--inner form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 7.5% 10%;
}
.Login .Login--inner form .floating-label {
  width: 100%;
  position: relative;
}
.Login .Login--inner form .floating-label input {
  display: flex;
  width: 100%;
  padding: 1rem;
  padding-top: 1.35rem;
  font-family: "Inter", sans-serif;
  background: #fff;
  border: 1px solid #ececec;
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #000;
  transition: 0.25s all ease;
}
.Login .Login--inner form .floating-label input:not(:-ms-input-placeholder) ~ label {
  top: 0.7rem;
  left: 1rem;
  font-size: 12px;
  -ms-transition: 0.25s all ease;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Login .Login--inner form .floating-label input:focus ~ label,
.Login .Login--inner form .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.7rem;
  left: 1rem;
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Login .Login--inner form .floating-label input:focus {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.Login .Login--inner form .floating-label label {
  color: #9a9a9a;
  transition: 0.25s all ease;
  position: absolute;
  left: 1rem;
  top: 1.6rem;
  pointer-events: none;
}
.Login .Login--inner form .inline-label {
  display: inline-flex;
  white-space: nowrap;
}
.Login .Login--inner form .inline-label label {
  cursor: pointer;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Login .Login--inner form .inline-label input[type="checkbox"] {
  position: relative;
  transform: translateX(-4px);
  cursor: pointer;
}
.Login .Login--inner form .inline-label input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 15.5px;
  height: 15px;
  top: 0;
  left: 0;
  border: 1.5px solid #000;
  border-radius: 3px;
  background-color: #fff;
}
.Login .Login--inner form .inline-label input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 1.5px;
  left: 5.25px;
}
.Login .Login--inner form a {
  color: #262a68;
  text-decoration: none;
  font-size: 14px;
  margin-left: auto;
  text-align: right;
  outline: none;
  padding: 2px;
  transition: 0.25s all ease;
}
.Login .Login--inner form a:hover,
.Login .Login--inner form a:focus {
  color: #000;
  transition: 0.25s all ease;
}
.Login .Login--inner form .Submit__Form {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.Login .Login--inner form .Submit__Form input[type="submit"] {
  font-family: "Inter", sans-serif;
  background: #000;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 0.75rem 4rem;
  border-radius: 10rem;
  text-transform: uppercase;
  margin-left: auto;
  margin-top: 2rem;
  transition: 0.25s all ease;
}
.Login .Login--inner form .Submit__Form input[type="submit"]:focus {
  box-shadow: 0 0 0 3px #a0d1fa;
  outline: none;
  transition: 0.25s all ease;
}
.Login .Login--inner form .Submit__Form input[type="submit"]:hover {
  transition: 0.25s all ease;
  background: #272727;
  cursor: pointer;
}
.Login .Login--inner form .Submit__Form input[type="submit"].true {
  background: #000;
  color: #000;
}
.Login .Login--inner .ForgotSuccess {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}
.Login .Login--inner .ForgotSuccess p {
  padding: 0 15px;
  text-align: center;
  color: #00b27e;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.Login .Login--inner .ForgotSuccess a {
  color: #fff;
  background: #000000;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 2rem;
  transition: 0.25s all ease;
}
.Login .Login--inner .ForgotSuccess a:hover {
  transition: 0.25s all ease;
  background: #272727;
  cursor: pointer;
}
.Login .Login--inner .SignUp {
  display: inline-flex;
  align-items: center;
  padding: 0 10%;
  width: 100%;
  flex-direction: column;
}
.Login .Login--inner .SignUp h5 {
  margin: 0;
  color: #535353;
  margin-bottom: 0.25rem;
  font-weight: 400;
  font-size: 1rem;
}
.Login .Login--inner .SignUp a {
  color: #00b27e;
  text-decoration: none;
  font-weight: 500;
  padding: 2px;
  transition: 0.25s all ease;
}
.Login .Login--inner .SignUp a:hover,
.Login .Login--inner .SignUp a:focus {
  transition: 0.25s all ease;
  color: #07ce94;
}
.Login .Login--inner .Login__Error {
  opacity: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Login .Login--inner .Login__Error p {
  transition: 0.25s all ease;
  margin-top: 0;
  margin-bottom: 0.25rem;
  color: #d40000;
  font-weight: 500;
  font-size: 0.9rem;
}
.Login .Login--inner .Login__Error.true {
  opacity: 1;
  transition: 0.25s all ease;
  -webkit-animation-name: true;
  animation-name: true;
}
.Login .Login__Image {
  display: none;
}
@media (min-width: 768px) {
  .Login .Login--inner {
    align-items: flex-start;
  }
  .Login .Login--inner h2,
  .Login .Login--inner h4 {
    padding-left: 25%;
  }
  .Login .Login--inner h2 {
    font-size: 3rem;
  }
  .Login .Login--inner h4 {
    text-align: left;
  }
  .Login .Login--inner form {
    padding: 7.5% 25%;
  }
  .Login .Login--inner form input[type="submit"] {
    margin-right: auto;
  }
  .Login .Login--inner .SignUp {
    padding: 0 25%;
  }
}
@media (max-width: 992px) {
  .Login .Login--inner form .Submit__Form {
    display: flex;
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .Login {
    margin-top: 0;
  }
  .Login .Login--inner {
    margin-top: 6rem;
    height: intrinsic;
  }
  .Login .Login--inner form {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
  }
  .Login .Login--inner form input[type="submit"] {
    margin-left: unset;
  }
  .Login .Login--inner .SignUp {
    flex-direction: row;
  }
  .Login .Login--inner .SignUp h5 {
    margin: 0;
    margin-right: 0.25rem;
  }
  .Login .Login__Image {
    display: block;
    width: 75%;
    position: relative;
  }
  .Login .Login__Image img:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
  .Login .Login__Image img:last-child {
    width: 100%;
    object-fit: cover;
  }
}
@media (min-width: 1200px) {
  .Login .Login--inner {
    margin-top: 8rem;
  }
  .Login .Login--inner h2,
  .Login .Login--inner h4 {
    padding-left: 30%;
    width: 100%;
  }
  .Login .Login--inner form {
    padding: 2.5% 30%;
  }
  .Login .Login--inner .SignUp {
    padding: 0 30%;
  }
  .Login .Login--inner .ForgotSuccess p {
    width: 60%;
  }
}
@media (min-width: 1400px) {
  .Login .Login--inner h2,
  .Login .Login--inner h4 {
    padding-left: 30%;
  }
  .Login .Login--inner form {
    padding: 2.5% 40% 2.5% 30%;
  }
  .Login .Login--inner .SignUp {
    padding: 0 40% 0 30%;
  }
}
.animated {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes true {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes true {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 16px auto;
  position: relative;
  font-size: 3px;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.loader.false {
  display: none;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.Register {
  margin-top: 3rem;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 150px;
  min-height: 100vh;
}
.Register .Register__helper {
  display: inline-flex;
  width: auto;
  flex-flow: column;
  flex-wrap: wrap;
  background: linear-gradient(0deg, #fff, #f4f8fc);
  padding: 2rem;
  padding-left: calc((100% - 1140px) / 2);
  margin-right: 5%;
}
.Register .Register__helper .Register--titles {
  width: 100%;
  text-align: right;
}
.Register .Register__helper .Register--titles .Register--titles--inner {
  display: inline-block;
}
.Register .Register__helper .Register--titles .Register--titles--inner h1 {
  width: 100%;
  font-size: 3rem;
  margin-bottom: 0;
  white-space: nowrap;
}
.Register .Register__helper .Register--titles .Register--titles--inner p {
  margin-top: 0;
  font-size: 1.1rem;
  text-align: left;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step {
  display: inline-flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 2rem;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one,
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-two,
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-three {
  font-family: "Inter", sans-serif;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
  font-weight: 600;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one {
  background: #0f1540;
  color: #fff;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one:before,
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one:after {
  content: "";
  position: absolute;
  height: 5px;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one:before {
  top: 45%;
  left: -80%;
  width: 25px;
  background: #0f1540;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one:after {
  left: 100%;
  width: 160px;
  background: #a8a8a8;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-two {
  color: #a8a8a8;
  background: #fff;
  border: 3px solid #a8a8a8;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-two:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 40%;
  background: #a8a8a8;
  height: 5px;
  width: 100px;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-three {
  color: #a8a8a8;
  background: #fff;
  border: 3px solid #a8a8a8;
}
.Register .Register__helper .Register__steps {
  margin-top: 2rem;
  margin-left: auto;
}
.Register .Register__helper .Register__steps .Register--step {
  display: flex;
  align-items: center;
  transition: all 0.25s ease;
  margin: 2rem 0;
  padding: 1rem;
  color: #a8a8a8;
}
.Register .Register__helper .Register__steps .Register--step .Register--step--inner {
  display: inline-block;
}
.Register .Register__helper .Register__steps .Register--step h2 {
  margin: 0 0 0.5rem 0;
}
.Register .Register__helper .Register__steps .Register--step p {
  margin: 0;
}
.Register .Register__helper .Register__steps .Register--step.active {
  box-shadow: #97b4cb32 0 3px 20px;
  background: #fff;
  border-radius: 6px;
}
.Register .Register__helper .Register__steps .Register--step.active h2 {
  color: #0f1540;
}
.Register .Register__helper .Register__steps .Register--step.active p {
  color: #0f1540;
}
.Register .Register__helper .Register__steps .Register--step.active:after {
  content: "";
  display: inline-block;
  background: url(/static/media/arrow.53dc66b3.svg) no-repeat;
  width: 30px;
  height: 15px;
  margin-left: 15px;
}
.Register .Register__container {
  display: inline-flex;
  width: 40%;
  flex-wrap: wrap;
}
.Register .Register__container .Register--inner {
  width: 469px;
  max-width: 80%;
}
.Register .Register__container .Register--inner h3 {
  font-size: 1.5rem;
  color: #262a68;
  border-bottom: solid 1px #dedede;
  padding-bottom: 0.5rem;
  width: 100%;
}
.Register .Register__container .Register--inner h4 {
  color: #262a68;
  font-size: 1.25rem;
  margin-top: 3rem;
  width: 100%;
  margin-bottom: 0.75rem;
}
.Register .Register__container .Register--inner form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Register .Register__container .Register--inner form .Fleet__cont {
  transition: all 0.5s linear;
  max-height: 500px;
}
.Register .Register__container .Register--inner form .hidden {
  max-height: 0;
  overflow: hidden;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst {
  display: none;
  align-items: center;
  flex-wrap: wrap;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst.active {
  display: flex;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block {
  display: inline-flex;
  width: 100%;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline:first-child {
  margin-right: 0.5rem;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label {
  position: relative;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label input:not(:-ms-input-placeholder) ~ label {
  top: 0.3rem;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label input:focus ~ label,
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.3rem;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label label {
  top: 1.25rem;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .error-message {
  top: -10px;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .dropdown-wrapper {
  width: 100%;
  position: relative;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .dropdown {
  width: 100%;
  margin-top: 0.75rem;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .dropdown.true {
  border: 1px solid #d40000;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .dropdown.invalid .react-select__control {
  border-color: #d40000;
}
.Register .Register__container .Register--inner form .dropdown > div {
  border-radius: 0;
  padding: 0.6rem 0;
  border: 1px solid #ececec;
}
.Register .Register__container .Register--inner form .floating-label {
  width: 100%;
  position: relative;
}
.Register .Register__container .Register--inner form .floating-label input {
  display: flex;
  width: 100%;
  padding: 1rem;
  padding-top: 1.35rem;
  font-family: "Inter", sans-serif;
  background: #fff;
  border: 1px solid #ececec;
  font-size: 1rem;
  margin-top: 0.75rem;
  color: #000;
  transition: 0.25s all ease;
}
.Register .Register__container .Register--inner form .floating-label input:not(:-ms-input-placeholder) ~ label {
  top: 0.85rem;
  left: 1rem;
  font-size: 12px;
  -ms-transition: 0.25s all ease;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Register .Register__container .Register--inner form .floating-label input:focus ~ label,
.Register .Register__container .Register--inner form .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.85rem;
  left: 1rem;
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Register .Register__container .Register--inner form .floating-label input:focus {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.Register .Register__container .Register--inner form .floating-label input:disabled {
  background: #e7e7e7;
}
.Register .Register__container .Register--inner form .floating-label label {
  color: #9a9a9a;
  transition: 0.25s all ease;
  position: absolute;
  left: 1rem;
  top: 2rem;
  pointer-events: none;
}
.Register .Register__container .Register--inner form .floating-label.true input {
  border-color: #d40000;
}
.Register .Register__container .Register--inner form .floating-label.invalid input {
  border-color: #d40000;
}
.Register .Register__container .Register--inner form .inline-label {
  display: inline-flex;
  white-space: nowrap;
}
.Register .Register__container .Register--inner form .inline-label label {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Register .Register__container .Register--inner form .inline-label input[type="checkbox"] {
  position: relative;
  transform: translateX(-4px);
  cursor: pointer;
}
.Register .Register__container .Register--inner form .inline-label input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 15.5px;
  height: 15px;
  top: 0;
  left: 0;
  border: 1.5px solid #000;
  border-radius: 3px;
  background-color: #fff;
}
.Register .Register__container .Register--inner form .inline-label input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 1.5px;
  left: 5.25px;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond {
  display: none;
  align-items: center;
  flex-wrap: wrap;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond.active {
  display: block;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .floating-label label {
  top: 1.3rem;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .floating-label input:not(:-ms-input-placeholder) ~ label {
  top: 0.3rem;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .floating-label input:focus ~ label,
.Register .Register__container .Register--inner .Registration__part--PartSecond .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.3rem;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .floating-label .error-message {
  top: -11px;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .Password .floating-label label {
  top: 2rem;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .Password .floating-label input:not(:-ms-input-placeholder) ~ label {
  top: 1rem;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .Password .floating-label input:focus ~ label,
.Register .Register__container .Register--inner .Registration__part--PartSecond .Password .floating-label input:not(:placeholder-shown) ~ label {
  top: 1rem;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .Password .floating-label .error-message {
  top: 0px;
}
.Register .Register__container .Register--inner .next-step {
  background: #00b27e;
  color: #fff;
  border-radius: 4px;
  padding: 1rem;
  border: none;
  display: inline-block;
  width: 200px;
  margin: auto;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 3rem;
  transition: 0.25s all ease;
  font-family: "Inter";
  cursor: pointer;
}
.Register .Register__container .Register--inner .next-step:hover {
  background: #07ce94;
}
.Register .Register__container .Register--inner .Password {
  margin: 3rem 0 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.Register .Register__container .Register--inner .termslinks {
  font-size: 0.8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  white-space: normal !important;
}
.Register .Register__container .Register--inner .termslinks a {
  color: #313131;
  text-decoration: underline;
}
.Register .Register__container .Register--inner .termslinks .error-message {
  position: relative;
  top: unset;
  right: unset;
}
.Register .Register__container .Register--inner .formSubmit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.Register .Register__container .Register--inner .formSubmit .next-step {
  margin: auto 0;
}
.Register .Register__container .Register--inner .formSubmit .next-step.back {
  background: #fff;
  border: 2px solid #050720;
  color: #050720;
  transition: 0.25s all ease;
  padding: 14px;
  width: 100px;
}
.Register .Register__container .Register--inner .formSubmit .next-step.back:hover {
  background: #050720;
  color: #fff;
}
.Register .Register__container .Register--inner .formSubmit.form-next {
  justify-content: flex-end;
}
.Register .Register__container .Register--inner .formSubmit .Submit__Wrapper {
  position: relative;
}
.Register .Register__container .Register--inner .formSubmit .Submit__Wrapper button.true {
  color: #00b27e;
}
.Register .Register__container .Register--inner .formSubmit .Submit__Wrapper .loader {
  display: none;
  position: absolute;
  top: 10px;
}
.Register .Register__container .Register--inner .formSubmit .Submit__Wrapper .loader.true {
  display: block;
}
.error-message {
  color: #d40000;
  font-size: 0.8rem;
  padding: 0.25rem;
  background: #fff;
  position: absolute;
  top: 0;
  right: 5px;
}
@media (max-width: 1200px) {
  .Register .Register__helper {
    padding-left: 5rem;
  }
}
@media (max-width: 992px) {
  .Register {
    flex-direction: column;
  }
  .Register .Register__helper {
    margin: 0;
    margin-bottom: 3rem;
    height: auto;
  }
  .Register .Register__helper .Register__steps {
    display: none;
  }
  .Register .Register__helper .Register--titles {
    text-align: center;
  }
  .Register .Register__container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .Register .Register__container .Register--inner h3 {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 578px) {
  .Register .Register__helper {
    padding: 2rem;
    margin-bottom: 0;
  }
  .Register .Register__helper .Register--titles .Register--titles--inner h1 {
    font-size: 2rem;
  }
  .Register .Register__helper .Register--titles .Register--titles--inner p {
    font-size: 1rem;
  }
  .Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one:after {
    width: 65px;
  }
  .Register .Register__container .Register--inner {
    max-width: 100%;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .Register .Register__container .Register--inner .formSubmit {
    display: flex;
    flex-direction: column;
  }
  .Register .Register__container .Register--inner .formSubmit .next-step.back {
    margin-bottom: 1rem;
  }
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 16px auto;
  position: relative;
  font-size: 3px;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.loader.false {
  display: none;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@supports (-ms-ime-align: auto) {
  .Register .Register__container .Register--inner form .floating-label label {
    position: absolute !important;
    top: 1rem !important;
    font-size: 12px;
  }
  .Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label label {
    top: 0.25rem !important;
  }
  .Register .Register__container .Register--inner form .Registration__part--PartSecond .floating-label label {
    top: 0.25rem !important;
  }
  .Register .Register__container .Register--inner form .Registration__part--PartSecond .Password .floating-label label {
    top: 1rem !important;
  }
}

.Left_Drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  transition: 0.25s all ease;
}
.Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav {
  padding: 1rem;
  box-shadow: 0px -1px 5px 0px rgba(151, 180, 203, 0.16);
  background: #fff;
}
.Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav ul {
  margin: 0;
  padding: 0;
  display: inline-flex;
  width: 100%;
  justify-content: space-around;
}
.Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 12px;
  align-items: center;
  color: #686a7d;
  line-height: 1.5;
}
.Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav ul li a {
  color: #686a7d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;
}
.Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav ul li svg {
  width: 20px;
  height: 20px;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items {
  position: fixed;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  width: 100%;
  height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #fff;
  padding-top: 1rem;
  padding-bottom: 3rem;
  z-index: 11;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul {
  margin: 1rem 0;
  padding: 0;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul hr {
  border: none;
  height: 1px;
  background: #dcdcdc;
  margin: 12px 15px;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li {
  list-style: none;
  position: relative;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a {
  margin: 0 0 0 2.5rem;
  padding: 0.75rem 0;
  padding-left: 0.5rem;
  color: #0f1540;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  position: relative;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a svg {
  width: 20px;
  height: 20px;
  margin-right: 2rem;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a span {
  background: #e13505;
  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 2px;
  padding: 2px;
  border-radius: 50%;
  left: 22px;
  min-width: 16px;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a .company-letters {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: #e13505;
  border-radius: 50%;
  margin-right: 0.75rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a.active {
  background: #66a1f9;
  color: #fff;
  font-weight: 600;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a.active svg path,
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a.active svg circle,
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a.active svg line {
  stroke: #fff;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a div div {
  display: flex;
  align-items: center;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li .link-tooltip {
  display: none;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items.true {
  transform: translateY(0);
}
.Left_Drawer .Left_Drawer--inner .Drawer--logo,
.Left_Drawer .Left_Drawer--inner .Drawer--company {
  display: none;
}
.Left_Drawer-close {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #000;
  opacity: 0.5;
  display: none;
  z-index: 11;
}
.Left_Drawer-close.true {
  display: block;
}
@media screen and (min-width: 992px) {
  .Left_Drawer {
    left: 0;
    top: 0;
    height: 100vh;
    width: 84px;
    background: #484f6b;
  }
  .Left_Drawer .Left_Drawer--inner {
    height: inherit;
    padding: 3rem 0;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo {
    margin-bottom: 2.5rem;
    padding: 0 2.25rem;
    display: block;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img {
    height: 30px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:first-child {
    width: 111px;
    min-width: 111px;
    display: none;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:last-child {
    width: 12px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company {
    margin-bottom: 1rem;
    padding: 0 1.5rem;
    overflow: hidden;
    display: block;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company a {
    color: #fff;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    color: #484f6b;
    white-space: nowrap;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company a .company-letters {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    min-width: 50px;
    height: 40px;
    background: #e13505;
    border-radius: 50%;
    margin-right: 0.75rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items {
    position: relative;
    transform: unset;
    height: unset;
    border-radius: 0;
    background: none;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul {
    margin: 0;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    overflow: visible;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a {
    overflow: hidden;
    padding: 0.75rem 2rem;
    color: #c7c7db;
    width: 100%;
    position: relative;
    margin: 0;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a span {
    left: 45px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: #fff;
    transition: 0.25s opacity ease;
    opacity: 0;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover {
    color: #fff;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover:before {
    transition: 0.25s opacity ease;
    opacity: 1;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover svg path,
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover svg circle,
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover svg line {
    stroke: #fff;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .Left_Drawer .Left_Drawer--inner .Drawer--items {
    overflow: visible;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li {
    position: relative;
    overflow: visible;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover ~ .link-tooltip {
    display: block;
  }
}
.link-tooltip {
  position: absolute;
  padding: 0.5rem;
  pointer-events: none;
  color: #fff;
  z-index: 1;
  font-size: 13px;
  left: 4.5rem;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.link-tooltip:before {
  content: "";
  position: absolute;
  left: -7px;
  top: 8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7.5px 7.5px 7.5px 0;
  border-color: transparent #3b3b3b transparent transparent;
  opacity: 0.9;
  border-radius: 3px;
}
.link-tooltip:after {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3b3b3b;
  opacity: 0.9;
  border-radius: 3px;
}
@media screen and (min-width: 992px) {
  .Left_Drawer {
    width: 270px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items .only-mobile {
    display: none;
  }
  .Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav {
    display: none;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:first-child {
    display: block;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:last-child {
    display: none;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company a {
    color: #fff;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company a .company-letters {
    width: 40px;
    min-width: 40px;
    margin-right: 1.2rem;
  }
  #left-drawer-small {
    width: 84px;
  }
  #left-drawer-small .Left_Drawer--inner .Drawer--logo img:first-child {
    display: none;
  }
  #left-drawer-small .Left_Drawer--inner .Drawer--logo img:last-child {
    width: 12px;
    display: block;
  }
  #left-drawer-small .Left_Drawer--inner .Drawer--items {
    overflow: visible;
  }
  #left-drawer-small .Left_Drawer--inner .Drawer--items ul li a:hover ~ .link-tooltip {
    display: block;
  }
}
.Right_Drawer {
  background: #fff;
  overflow-y: auto;
  display: none !important;
  z-index: 11;
}
.Right_Drawer .Right_Drawer--inner .Drawer--title {
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}
.Right_Drawer .Right_Drawer--inner .Drawer--title .notification-icon {
  margin-right: 0.75rem;
}
.Right_Drawer .Right_Drawer--inner .Drawer--title .notification-icon .notifications-blob:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #e13505;
  right: -0.3rem;
  bottom: 0.1rem;
  border-radius: 50%;
}
.Right_Drawer .Right_Drawer--inner .Drawer--title h3 {
  font-size: 18px;
  color: #050720;
  font-weight: 700;
  margin: 0;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #707070;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item .item--head {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item .item--head h4 {
  margin: 0;
  color: #989898;
  font-size: 12px;
  font-weight: 500;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item .item--head svg:hover {
  cursor: pointer;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item .item--head svg:hover path {
  stroke: #000;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item .item--content {
  margin-top: 0.75rem;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item .item--content p {
  margin: 0;
  margin-bottom: 1rem;
  color: #3b3b3b;
  line-height: 1.4;
  font-size: 14px;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item .item--content a {
  text-decoration: none;
  color: #3990b7;
  font-size: 14px;
}
.Right_Drawer .Right_Drawer--inner .Right_Drawer--close {
  display: none;
}
@media screen and (min-width: 992px) {
  .Right_Drawer {
    transition: 0.25s all ease;
    height: 100vh;
    width: 270px;
    display: block;
  }
  .Right_Drawer .Right_Drawer--inner {
    padding: 6rem 2rem 0 2rem;
  }
  #right-drawer-small {
    transform: translateX(105%);
    position: fixed;
    right: 0;
    overflow: visible;
    z-index: 11;
  }
  #right-drawer-small .Right_Drawer--inner {
    padding: 6rem 2rem 0 2rem;
    position: relative;
  }
  #right-drawer-small .Right_Drawer--inner .Drawer--title .notification-icon {
    transform: translateX(-159%);
    position: fixed;
    margin: 0;
    padding: 1rem 2rem 1em 1rem;
    display: flex;
    align-items: center;
  }
  #right-drawer-small .Right_Drawer--inner .Drawer--title .notification-icon:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
    z-index: -1;
    top: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  #right-drawer-small .Right_Drawer--inner .Drawer--title .notification-icon:hover {
    cursor: pointer;
  }
  #right-drawer-small .Right_Drawer--inner .Drawer--title .notification-icon .notifications-blob:before {
    right: 1.7rem;
    bottom: 1.05rem;
  }
  #right-drawer-small .Right_Drawer--inner .Right_Drawer--close {
    display: block;
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #right-drawer-small .Right_Drawer--inner .Right_Drawer--close:hover {
    cursor: pointer;
  }
  #right-drawer-small.true {
    transform: translateX(0);
  }
  #right-drawer-small.true .Right_Drawer--inner .Drawer--title .notification-icon {
    position: relative;
    transform: none;
    padding: 0;
    margin-right: 0.75rem;
  }
  #right-drawer-small.true .Right_Drawer--inner .Drawer--title .notification-icon:before {
    content: unset;
  }
  #right-drawer-small.true .Right_Drawer--inner .Drawer--title .notification-icon .notifications-blob:before {
    right: -0.3rem;
    bottom: 0.1rem;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .Left_Drawer {
    width: 84px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:first-child {
    display: none;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:last-child {
    width: 12px;
    display: block;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company a .company-letters {
    width: 40px;
    min-width: 40px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items {
    overflow: visible;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover ~ .link-tooltip {
    display: block;
  }
  .Right_Drawer {
    transform: translateX(105%);
    position: fixed;
    right: 0;
    overflow: visible;
    z-index: 11;
  }
  .Right_Drawer .Right_Drawer--inner {
    padding: 6rem 2rem 0 2rem;
    position: relative;
  }
  .Right_Drawer .Right_Drawer--inner .Drawer--title .notification-icon {
    transform: translateX(-159%);
    position: fixed;
    margin: 0;
    padding: 1rem 2rem 1em 1rem;
    display: flex;
    align-items: center;
  }
  .Right_Drawer .Right_Drawer--inner .Drawer--title .notification-icon:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
    z-index: -1;
    top: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .Right_Drawer .Right_Drawer--inner .Drawer--title .notification-icon:hover {
    cursor: pointer;
  }
  .Right_Drawer .Right_Drawer--inner .Drawer--title .notification-icon .notifications-blob:before {
    right: 1.7rem;
    bottom: 1.05rem;
  }
  .Right_Drawer .Right_Drawer--inner .Right_Drawer--close {
    display: block;
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Right_Drawer .Right_Drawer--inner .Right_Drawer--close:hover {
    cursor: pointer;
  }
  .Right_Drawer.true {
    transform: translateX(0);
  }
  .Right_Drawer.true .Right_Drawer--inner .Drawer--title .notification-icon {
    position: relative;
    transform: none;
    padding: 0;
    margin-right: 0.75rem;
  }
  .Right_Drawer.true .Right_Drawer--inner .Drawer--title .notification-icon:before {
    content: unset;
  }
  .Right_Drawer.true .Right_Drawer--inner .Drawer--title .notification-icon .notifications-blob:before {
    right: -0.3rem;
    bottom: 0.1rem;
  }
}
@media screen and (min-width: 1024px) {
  .Left_Drawer-close {
    display: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .Right_Drawer {
    transform: translateX(0);
  }
  .Right_Drawer .Right_Drawer--inner .notification-icon {
    transform: translateX(0);
  }
}

.Mini_Messages {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  width: auto;
  z-index: 1001;
  margin-right: 1rem;
  pointer-events: none;
}
.Mini_Messages .Mini_Messages--inner {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  pointer-events: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message {
  pointer-events: all;
  width: 300px;
  height: 360px;
  margin-left: 1rem;
  background: #fff;
  border: 1px solid #eeeeee;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header {
  align-items: center;
  display: inline-flex;
  width: 100%;
  border-bottom: 1px solid #eee;
  background: #f9f9f9;
  height: 50px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header-minimize {
  background: #f9f9f9;
  padding: 0.5rem 0 0.5rem 0.5rem;
  width: 245px;
  height: 50px;
  min-width: 245px;
  display: flex;
  border-top-left-radius: 6px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header-minimize:hover {
  cursor: pointer;
  background: #eaeaea;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--image {
  width: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  pointer-events: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--image img {
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: contain;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--recipent {
  width: 204px;
  min-width: 204px;
  pointer-events: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--recipent h3,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--recipent h4 {
  display: block;
  padding: 0 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  margin: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--recipent h3 {
  color: #000;
  font-size: 13px;
  font-weight: 600;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--recipent h4 {
  color: #686a7d;
  font-size: 12px;
  font-weight: 500;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 54px;
  min-width: 54px;
  max-width: 54px;
  padding-right: 5px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options {
  width: 25px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options img {
  width: 100%;
  height: 17.5px;
  pointer-events: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 91;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul {
  margin: 0;
  padding: 0;
  background: #fff;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul li {
  white-space: nowrap;
  list-style-type: none;
  display: flex;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul li a {
  font-size: 14px;
  width: 100%;
  padding: 1rem;
  text-decoration: none;
  color: #191919;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul li a:active,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul li a:focus {
  color: #191919;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul li a:hover {
  background: #f9f9f9;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options:hover {
  cursor: pointer;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .close {
  width: 25px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .close img {
  width: 100%;
  height: 15px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .close:hover {
  cursor: pointer;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box {
  height: 310px;
  position: relative;
  background: #fff;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner {
  padding: 0 0.5rem 0.5rem 0.75rem;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
  transition: 0.25s height ease;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message {
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] {
  margin-top: 2.5rem;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] .sender {
  border-top-right-radius: 15px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] .reciever {
  border-top-left-radius: 15px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-end] .sender {
  border-bottom-right-radius: 15px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-end] .reciever {
  border-bottom-left-radius: 15px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message .reciever-avatar {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  object-fit: contain;
  position: absolute;
  left: -12px;
  bottom: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message .seen {
  position: absolute;
  right: -17.5px;
  bottom: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever {
  padding: 0.5rem 0.75rem;
  display: flex;
  display: block;
  flex-wrap: wrap;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  border-radius: 15px;
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender h1,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever h1,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender h2,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever h2,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender h3,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever h3,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender h4,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever h4,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender h5,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever h5,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender p,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever p,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender span,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever span,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender li,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever li,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender a,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever a {
  margin: 0;
  line-height: 1.4;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender p,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever p {
  width: 100%;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender a,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever a {
  color: #66a1f9;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender strong,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever strong {
  font-weight: 600;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender:hover:before,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever:hover:before {
  content: attr(data-created);
  position: absolute;
  top: -21px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.1rem 0.25rem;
  border-radius: 3px;
  line-height: 1.5;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  font-size: 12px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin: 2px 0 2px auto;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever {
  background: #eef9f5;
  margin-right: auto;
  position: relative;
  margin: 2px auto 2px 1.25rem;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message {
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] {
  margin-top: 2.5rem;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message .reciever-avatar {
  margin-left: 2px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message .seen {
  position: absolute;
  right: -17.5px;
  bottom: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender {
  background: #d5f1fe;
  justify-content: flex-start;
  margin-left: auto;
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender .sender-seen {
  position: absolute;
  bottom: -0.5rem;
  right: -1rem;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar-track {
  background-color: #fff;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar {
  background-color: #eaeaea;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar-thumb {
  background-color: #c5c6d3;
  border: 5px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  min-height: 70px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message {
  min-height: 60px;
  border-top: 1px solid #eee;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner {
  display: inline-flex;
  width: 100%;
  min-height: 60px;
  align-items: center;
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box {
  display: inline-flex;
  width: 100%;
  max-width: 260px;
  min-height: 60px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-toolbar,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper {
  border: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .second-toolbar,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-newline {
  display: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-toolbar .fr-btn-grp {
  margin: 0 5px;
  display: flex;
  flex-direction: column;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-toolbar .fr-btn-grp button {
  width: 24px;
  height: 27px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-toolbar .fr-btn-grp button .fr-svg {
  margin: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-toolbar .fr-popup {
  top: unset !important;
  left: 0px !important;
  bottom: 60px !important;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper {
  width: 100%;
  max-width: 235px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper .fr-element {
  max-height: 164px;
  overflow-y: auto;
  padding-left: 0.5rem !important;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper .fr-element::-webkit-scrollbar-track {
  background-color: #fff;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper .fr-element::-webkit-scrollbar {
  background-color: #eaeaea;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper .fr-element::-webkit-scrollbar-thumb {
  background-color: #c5c6d3;
  border: 5px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  min-height: 40px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .Send_Icon {
  width: 40px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 6px;
  position: absolute;
  top: 0;
  right: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .Send_Icon img {
  width: 30px;
  height: 30px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .Send_Icon:hover {
  cursor: pointer;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box.minimize {
  height: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message.minimize {
  height: 50px;
}
.Mini_Messages.hide {
  display: none;
}
@media screen and (min-width: 992px) {
  .Mini_Messages {
    display: block;
  }
  .Mini_Messages.pos-adjusted {
    margin-right: calc(400px + 1rem);
  }
}
.fr-tooltip {
  display: none;
}

.Checkout {
  height: 100vh;
}
.Checkout .Checkout__Navbar header {
  height: 60px;
  display: flex;
  align-items: center;
}
.Checkout .Checkout__Navbar header .logo {
  display: flex;
  width: 120px;
}
.Checkout .Checkout__Navbar header .logo img {
  max-width: 100%;
  height: auto;
}
.Checkout .Checkout__Steps {
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 1.5rem 0;
}
.Checkout .Checkout__Steps a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}
.Checkout .Checkout__Steps .Step {
  margin: 0 0.5rem;
}
.Checkout .Checkout__Steps .Step h4 {
  margin: 0;
  color: #0f1540;
  font-weight: 500;
}
.Checkout .Checkout__Steps .Step.completed h4 {
  color: #00b27e;
}
.Checkout .Checkout__Steps .Step.not-completed h4 {
  color: #a8a8a8;
}
.Checkout .Checkout__Steps .Step.step-current h4 {
  font-weight: 600;
}
.Checkout .Checkout__Steps .Checkout--da {
  position: absolute;
  right: 0;
}
.Checkout .Checkout__Steps .Step--arrow.not-completed svg g line,
.Checkout .Checkout__Steps .Step--arrow.not-completed svg g path {
  stroke: #a8a8a8;
}
.Checkout .Content {
  padding-bottom: 250px;
}
.Checkout .Content .Summary {
  background: #ffffff;
  background: linear-gradient(0deg, #ffffff 0%, #f4f8fc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f4f8fc", GradientType=1);
}
.Checkout .Content .Summary .Summary--inner {
  padding: 2rem 1rem;
}
.Checkout .Content .Summary .Summary--inner .Back a {
  color: #262a68;
  text-decoration: none;
  font-size: 14px;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  border: 1px solid #262a68;
  font-weight: 400;
  transition: 0.25s all ease;
}
.Checkout .Content .Summary .Summary--inner .Back a:hover {
  transition: 0.25s all ease;
  background: #262a68;
  color: #fff;
}
.Checkout .Content .Summary .Summary--inner h3 {
  margin-bottom: 2rem;
  color: #050720;
  font-size: 1.25rem;
}
.Checkout .Content .Summary .Summary--inner .Summary--list h5 {
  color: #050720;
  font-weight: 500;
  font-size: 1rem;
  border-bottom: 1px solid #050720;
  padding-bottom: 0.25rem;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item {
  display: inline-flex;
  width: 100%;
  padding: 0.5rem 0;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item h4,
.Checkout .Content .Summary .Summary--inner .Summary--list .Item h5 {
  margin: 0;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item h5 {
  border-bottom: none;
  white-space: nowrap;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item h4 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #0f1540;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item.true {
  position: relative;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item.true span {
  color: #a8a8a8;
  text-decoration: line-through;
  padding-right: 0.5rem;
  font-size: 14px;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Total-wrapper {
  border-top: 1px solid #050720;
}
.Checkout .Content .Summary .Summary--ds {
  position: absolute;
  left: 0;
  bottom: 15%;
}
.Checkout .Content .Billing {
  padding: 0 15px;
  max-width: 600px;
}
.Checkout .Content .Billing h2 {
  color: #262a68;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1rem;
}
.Checkout .Content .Billing form#checkoutForm .floating-label {
  margin: 0.5rem 0;
}
.Checkout .Content .Billing form .floating-label {
  width: 100%;
  position: relative;
}
.Checkout .Content .Billing form .floating-label input {
  display: flex;
  width: 100%;
  padding: 1rem;
  padding-top: 1.35rem;
  font-family: "Inter", sans-serif;
  background: #fff;
  border: 1px solid #ececec;
  font-size: 1rem;
  color: #000;
  transition: 0.25s all ease;
}
.Checkout .Content .Billing form .floating-label input:not(:-ms-input-placeholder) ~ label {
  top: 0.2rem;
  left: 1rem;
  font-size: 12px;
  -ms-transition: 0.25s all ease;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Checkout .Content .Billing form .floating-label input:focus ~ label,
.Checkout .Content .Billing form .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.2rem;
  left: 1rem;
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Checkout .Content .Billing form .floating-label input:focus {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.Checkout .Content .Billing form .floating-label label {
  color: #9a9a9a;
  transition: 0.25s all ease;
  position: absolute;
  left: 1rem;
  top: 1.25rem;
  pointer-events: none;
}
.Checkout .Content .Billing form .inline-label {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.Checkout .Content .Billing form .inline-label .floating-label {
  width: auto;
}
.Checkout .Content .Billing form .inline-label .floating-label label {
  top: 1.2rem;
}
.Checkout .Content .Billing form .inline-label .floating-label:first-child {
  margin-right: 0.5rem;
}
.Checkout .Content .Billing form .inline-label .floating-label input:not(:-ms-input-placeholder) ~ label {
  top: 0.7rem;
}
.Checkout .Content .Billing form .inline-label .floating-label input:focus ~ label,
.Checkout .Content .Billing form .inline-label .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.7rem;
}
.Checkout .Content .Billing form .checkboxes {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
}
.Checkout .Content .Billing form .checkboxes label {
  margin: 0.5rem 0;
  cursor: pointer;
  line-height: 1.5;
}
.Checkout .Content .Billing form .checkboxes input[type="checkbox"] {
  position: relative;
  transform: translateX(-4px);
}
.Checkout .Content .Billing form .checkboxes input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 15.5px;
  height: 15px;
  top: 0;
  left: 0;
  border: 1.5px solid #000;
  border-radius: 3px;
  background-color: #fff;
}
.Checkout .Content .Billing form .checkboxes input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 1.5px;
  left: 5.25px;
}
.Checkout .Content .Billing form .Submit {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
  position: relative;
}
.Checkout .Content .Billing form .Submit input {
  border: none;
  color: #fff;
  font-family: "Inter", sans-serif;
  padding: 1rem 3rem;
  background: #00b27e;
  cursor: pointer;
  border-radius: 3px;
  font-size: 1rem;
  transition: 0.25s all ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.Checkout .Content .Billing form .Submit input:hover {
  transition: 0.25s all ease;
  background: #07ce94;
}
.Checkout .Content .Billing form .Submit input.true {
  color: #00b27e;
}
.Checkout .Content .Billing form .Submit input.true:hover {
  color: #07ce94;
}
.Checkout .Content .Billing .Discount .floating-label.valid input {
  border-color: #07ce94;
}
.Checkout .Content .Billing .Discount .floating-label.invalid input {
  border-color: #e13505;
}
.Checkout .Content .Billing .Discount form {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}
.Checkout .Content .Billing .Discount form .Discount__Apply {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  position: relative;
}
.Checkout .Content .Billing .Discount form .Discount__Apply input[type="submit"] {
  margin: 0;
  margin-left: 0.5rem;
  padding: 1.25rem 3rem;
  font-family: "Inter", sans-serif;
  border: none;
  background: #fff;
  border: 1px solid #262a68;
  border-radius: 3px;
  color: #262a68;
  font-weight: 500;
  transition: 0.25s all ease;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.Checkout .Content .Billing .Discount form .Discount__Apply input[type="submit"]:hover {
  transition: 0.25s all ease;
  background: #262a68;
  color: #fff;
}
.Checkout .Content .Billing .Discount form .Discount__Apply.true input[type="submit"] {
  color: #fff;
}
.Checkout .Content .Billing .Discount form .Discount__Apply.true input[type="submit"]:hover {
  color: #262a68;
}
.Checkout .Content .Billing .Discount form .Discount__Apply.true input[type="submit"]:not(:hover) ~ .loader {
  color: #262a68;
  background: #262a68;
}
.Checkout .Content .Billing .Discount form .Discount__Apply.true input[type="submit"]:not(:hover) ~ .loader:before,
.Checkout .Content .Billing .Discount form .Discount__Apply.true input[type="submit"]:not(:hover) ~ .loader:after {
  background: #262a68;
}
@media screen and (max-width: 772px) {
  .Checkout .Content .Billing form .inline-label {
    display: block;
  }
}
@media (min-width: 578px) {
  .Checkout .Content .Billing .Discount form {
    width: 100%;
    display: inline-flex;
  }
  .Checkout .Content .Billing .Discount form .floating-label label {
    top: 1.7rem;
  }
  .Checkout .Content .Billing .Discount form .floating-label input {
    margin: 0.5rem 0;
  }
  .Checkout .Content .Billing .Discount form .floating-label input:not(:-ms-input-placeholder) ~ label {
    top: 0.7rem;
  }
  .Checkout .Content .Billing .Discount form .floating-label input:focus ~ label,
  .Checkout .Content .Billing .Discount form .floating-label input:not(:placeholder-shown) ~ label {
    top: 0.7rem;
  }
  .Checkout .Content .Billing .Discount form input[type="submit"] {
    margin: 0.5rem 0 0.5rem 0.5rem;
    padding: 1rem 3rem;
    font-family: "Inter", sans-serif;
    -webkit-appearance: none;
  }
  #cvv {
    margin-right: 0.5rem;
  }
}
@media (min-width: 992px) {
  .Checkout .Checkout__Navbar header {
    height: 130px;
  }
  .Checkout .Checkout__Navbar header .logo {
    width: 240px;
  }
  .Checkout .Checkout__Steps {
    margin: 0;
    padding-left: 15%;
    height: 70px;
  }
  .Checkout .Content {
    padding-bottom: 0;
    display: inline-flex;
    width: 100%;
    height: calc(100vh - 200px);
  }
  .Checkout .Content .Summary {
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .Checkout .Content .Summary .Summary--inner {
    padding: 3rem;
  }
  .Checkout .Content .Billing {
    padding: 1rem 4rem 3rem 4rem;
  }
  .Checkout .Content .Billing form {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .Checkout .Content .Summary .Summary--inner {
    width: 400px;
  }
}
@media (max-width: 1200px) {
  .Checkout .Content .Summary .Summary--inner {
    max-width: 350px;
  }
}
@media (max-width: 992px) and (min-width: 578px) {
  .Checkout .Content .Summary .Summary--inner {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    padding: 3rem;
  }
  .Checkout .Content .Billing {
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 380px) {
  .Checkout .Checkout__Steps .Step h4 {
    font-size: 12px;
  }
}
.Payment__Error {
  opacity: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Payment__Error p {
  display: none;
  transition: 0.25s all ease;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #d40000;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.5;
}
.Payment__Error.true {
  opacity: 1;
  transition: 0.25s all ease;
  -webkit-animation-name: true;
  animation-name: true;
}
.Payment__Error.true p {
  display: block;
}
.Success h3 {
  color: #262a68;
  padding-bottom: 1rem;
  border-bottom: 0.5px solid #dedede;
  font-size: 1.5rem;
  line-height: 1.5;
}
.Success h4 {
  color: #0f1540;
  line-height: 1.5;
}
.Success ul {
  margin: 0;
  padding: 0;
}
.Success ul li {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0.5rem 0;
}
.Success ul li svg {
  margin-right: 0.5rem;
}
.Success a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  background: #00b27e;
  padding: 1rem 3rem;
  border-radius: 3px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  margin-top: 3rem;
  transition: all 0.25s ease;
}
.Success a:hover {
  transition: all 0.25s ease;
  background: #07ce94;
}
.animated {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes true {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes true {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 16px auto;
  position: relative;
  font-size: 3px;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -15%);
  display: block;
}
.loader.false {
  display: none;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
#card-number,
#cvv,
#expiration-date {
  border: 1px solid #ececec;
  height: 58.8px;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  background: #fff;
}
.braintree-hosted-fields-focused {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.braintree-hosted-fields-focused ~ label {
  transform: translateY(-1rem);
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
#credit-card-number:not(:-ms-input-placeholder) ~ label {
  top: 0.2rem;
  left: 1rem;
  font-size: 12px;
  -ms-transition: 0.25s all ease;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
#credit-card-number:not(:placeholder-shown) ~ label {
  top: 0.2rem;
  left: 1rem;
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.floating-label.not-empty label {
  transform: translateY(-1rem);
  font-size: 12px;
  transition: 0.25s all ease;
}

.Messages_List {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fbfbfc;
  min-height: 100vh;
  overflow-y: auto;
}
.Messages_List .Messages_List--inner {
  padding: 1rem;
  background: #fff;
  box-shadow: 10px 3px 27px 0px rgba(151, 180, 203, 0.16);
}
.Messages_List .Messages_List--inner .Messages_Search {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection {
  width: 100%;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper {
  position: relative;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input input {
  width: 100%;
  padding: 0.75rem;
  padding-left: 4rem;
  border: 1px solid #9aabc2;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  color: #292929;
  font-size: 1rem;
  font-weight: 600;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input input::-webkit-input-placeholder {
  color: #a8a8a8;
  font-weight: 400;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input input:-ms-input-placeholder {
  color: #a8a8a8;
  font-weight: 400;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input input::-ms-input-placeholder {
  color: #a8a8a8;
  font-weight: 400;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input input::placeholder {
  color: #a8a8a8;
  font-weight: 400;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input input:focus {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  position: absolute;
  left: 1rem;
  top: 0.75rem;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select {
  position: relative;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box {
  background: #fff;
  border: 1px solid #9aabc2;
  border-radius: 4px;
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  position: relative;
  width: 100%;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box h3 {
  margin: 0;
  color: #686a7d;
  font-weight: 400;
  font-size: 14px;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box img {
  position: absolute;
  right: 1rem;
  top: 1.1rem;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box.show {
  margin-top: -1px;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box.show img {
  transform: rotate(180deg);
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box.show ~ .Filter_Select_Options {
  transition: 0.25s all ease;
  max-height: 500px;
  opacity: 1;
  overflow: visible;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Filter_Select_Options {
  z-index: 4;
  position: absolute;
  border: 1px solid #9aabc2;
  border-top: none;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: 41px;
  left: 0;
  background: #fff;
  width: 100%;
  display: flex;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: 0.25s all ease;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Filter_Select_Options ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Filter_Select_Options ul li {
  list-style-type: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  color: #686a7d;
  line-height: 1.6;
  cursor: pointer;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Filter_Select_Options ul li:hover {
  background: #66a1f9;
  color: #fff;
}
.Messages_List .Messages_List--inner .Messages {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}
.Messages_List .Messages_List--inner .Messages .Messages--header {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  height: 50px;
}
.Messages_List .Messages_List--inner .Messages .Messages--header h2 {
  color: #292929;
  font-size: 1.25rem;
  margin: 0;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select {
  position: relative;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box {
  background: #fff;
  border: 1px solid #9aabc2;
  border-radius: 4px;
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  position: relative;
  width: 155px;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box h3 {
  margin: 0;
  color: #686a7d;
  font-weight: 400;
  font-size: 14px;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box img {
  position: absolute;
  right: 1rem;
  top: 1.1rem;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box.show {
  margin-top: -1px;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box.show img {
  transform: rotate(180deg);
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box.show ~ .Filter_Select_Options {
  transition: 0.25s all ease;
  max-height: 500px;
  opacity: 1;
  overflow: visible;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select_Options {
  z-index: 3;
  position: absolute;
  border: 1px solid #9aabc2;
  border-top: none;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: 41px;
  left: 0;
  background: #fff;
  width: 100%;
  display: flex;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: 0.25s all ease;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select_Options ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select_Options ul li {
  list-style-type: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  color: #686a7d;
  line-height: 1.6;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select_Options ul li:hover {
  background: #66a1f9;
  color: #fff;
}
.Messages_List .Messages_List--inner .Messages .All_Messages {
  margin: 1rem 0;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper {
  position: relative;
  padding: 0.75rem 0 1rem 1.25rem;
  border-radius: 6px;
  margin: 1rem 0;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper h4,
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper h5 {
  margin: 0;
  line-height: 1.5;
  width: 100%;
  padding-right: 3.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper h4 {
  color: #292930;
  font-weight: 700;
  padding-bottom: 0.5rem;
  padding-top: 0.25rem;
  font-size: 1rem;
  pointer-events: none;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper h5 {
  color: #686868;
  font-weight: 400;
  font-size: 14px;
  pointer-events: none;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper.has-unread h5 {
  pointer-events: none;
  font-weight: 500;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code.Tender {
  background: #a5c6dc;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code.Roundtrip {
  background: #a5dcc6;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code.OTL {
  background: #f3dcbd;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code.Tender {
  margin: 0;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code.Spotload {
  background: #cca5dc;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .unread-messages-count {
  pointer-events: none;
  position: absolute;
  top: 1rem;
  right: 0.6rem;
  width: 25px;
  height: 25px;
  background: #e13505;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .last-message-sent-date {
  pointer-events: none;
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
  font-size: 12px;
  color: #b7b8ba;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper.active {
  box-shadow: 10px 3px 27px 0px rgba(151, 180, 203, 0.16);
  background: #fff;
}
.Messages_List.empty-list {
  height: auto;
  min-height: auto;
}
@media screen and (min-width: 1200px) {
  .Messages .Messages--inner .Messages_List {
    width: 320px;
    height: 100vh;
    min-width: 320px;
    border: 1px solid #e7e8eb;
    padding: 1rem;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner {
    padding: 0;
    background: none;
    box-shadow: none;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner .Messages .Messages--header {
    background: none;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner .Messages .Messages--header h2 {
    overflow: hidden;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select:hover {
    cursor: pointer;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper:hover {
    cursor: pointer;
    background: #f5f5f5;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper.active:hover {
    background: #fff;
  }
  .Messages .Messages--inner .Messages_List::-webkit-scrollbar-track {
    background-color: #fbfbfc;
  }
  .Messages .Messages--inner .Messages_List::-webkit-scrollbar {
    background-color: #eaeaea;
  }
  .Messages .Messages--inner .Messages_List::-webkit-scrollbar-thumb {
    background-color: #c5c6d3;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
}
@media screen and (min-width: 1500px) {
  .Messages .Messages--inner .Messages_List {
    width: 360px;
    min-width: 360px;
  }
}
.Messages .Welcome_Mat {
  width: 100%;
  padding: 0;
}
.Messages .Welcome_Mat .Welcome_Mat--inner {
  height: 100%;
}
@media (max-width: 992px) {
  .Messages {
    margin-top: 64px;
  }
}

.Card.Progress {
  position: relative;
}
.Progress-header h3 {
  font-weight: 700;
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.6;
}
.Progress-header h3 span {
  color: #3990b7;
}
.Progress-header a {
  width: 200px;
  color: #707184;
  padding: 1.25rem 0.5rem;
  border: 1px solid #707184;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  transition: 0.25s all ease;
}
.Progress-header a:hover {
  background: #707184;
  color: #fff;
  transition: 0.25s all ease;
}
.progress-bar {
  display: inline-flex;
  width: 100%;
}
.progress-bar h6 {
  transform: translateY(10px);
  margin: 0;
  padding: 0 0.25rem;
  text-align: right;
  white-space: nowrap;
  color: #8a8a8a;
  font-weight: 500;
}
.progress-bar h6:first-child {
  width: 56%;
}
.progress-bar h6:nth-child(2) {
  width: 25%;
}
.progress-bar h6:nth-child(3) {
  width: 25%;
  transform: translate(20px, 10px);
}
.progress-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: #d2d2d2;
}
.progress-line:before {
  content: "";
  position: absolute;
  left: 0;
  height: 8px;
  background: #00b27e;
}
.progress-line.one:before {
  width: 50%;
}
.progress-line.two:before {
  width: 75%;
}
@media screen and (min-width: 992px) {
  .Progress-header {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .Progress-header img {
    margin-right: 1rem;
  }
  .Progress-header h3 {
    padding-right: 4rem;
  }
}

.Card {
  border: 1px solid #eeeeee;
  border-radius: 7px;
  padding: 1.5rem;
  background: #fff;
  width: 100%;
  margin-top: 1rem;
  position: unset;
}
.Card .Card-header {
  width: 100%;
  margin-bottom: 2rem;
  display: inline-flex;
  align-items: center;
}
.Card .Card-header h3 {
  margin: 0;
  color: #262a68;
  font-size: 1.25rem;
  padding-right: 1.5rem;
}
.Card .Card-header img {
  margin-right: 1rem;
}
.Card .Card-header a {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #262a68;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #262a68;
  border-radius: 4px;
  margin-left: auto;
  top: 1.25rem;
  right: 1rem;
  transition: 0.25s all ease;
}
.Card .Card-header a:hover {
  background: #262a68;
  color: #fff;
  transition: 0.25s all ease;
}
.Card .Card-content .all-button,
.Card .Card-content .add-button {
  display: flex;
  width: 100%;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;
}
.Card .Card-content .all-button a,
.Card .Card-content .add-button a {
  text-decoration: none;
  font-weight: 500;
}
.Card .Card-content .add-button a {
  color: #0184e8;
  border: 1px solid #0184e8;
  border-radius: 50px;
  padding: 1rem 2.75rem;
  transition: 0.25s all ease;
  text-decoration: none;
}
.Card .Card-content .add-button a:hover {
  transition: 0.25s all ease;
  background: #0184e8;
  color: #fff;
}
.Card .Card-content .all-button a {
  color: #292930;
  border-bottom: 1px solid #292930;
  font-size: 1.1rem;
}
.Card .Card-content .Latest {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding-left: 1rem;
}
.Card .Card-content .Latest a {
  text-decoration: none;
}
.Card .Card-content .Latest h5 {
  color: #050720;
  font-size: 14px;
  font-weight: 500;
  margin: 1rem 0;
  line-height: 1.5;
}
.Card .Table {
  padding: 0;
}
.Card .Table .Table--inner .Table-content .Table-row {
  padding: 0;
  box-shadow: none;
  border: none;
  margin: 0;
}
.Card .Table .Table--inner .Table-content .Table-row .item:first-child {
  padding-top: 0.75rem;
}
@media screen and (min-width: 800px) {
  .Card-inline {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .Card-inline .Card {
    width: calc(50% - 2rem);
  }
  .Card-inline .Card:nth-child(odd) {
    margin-right: 1rem;
  }
}
@media screen and (min-width: 992px) {
  .Card .Card-header {
    display: inline-flex;
  }
  .Card .Card-header h3 {
    font-size: 1.5rem;
  }
  .Card .Card-header a {
    padding: 0.5rem 2rem;
  }
  .Card .Table .Table--inner .Table-content .Table-row .item:first-child {
    padding-top: 0;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .Card-inline {
    display: flex;
  }
  .Card-inline .Card {
    width: 100%;
  }
  .Card-inline .Card:nth-child(odd) {
    margin-right: 0rem;
  }
}

.Company_Info .Company_Info-block {
  padding-right: 2rem;
  margin-bottom: 2rem;
}
.Company_Info .Company_Info-block .item {
  margin: 1rem 0;
  display: inline-flex;
  width: 100%;
}
.Company_Info .Company_Info-block .item h4,
.Company_Info .Company_Info-block .item h5 {
  margin: 0;
}
.Company_Info .Company_Info-block .item h4 {
  color: #686a7d;
  font-size: 14px;
  font-weight: 400;
  width: 150px;
  text-align: right;
  padding-right: 1rem;
}
.Company_Info .Company_Info-block .item h5 {
  color: #050720;
  font-size: 14px;
}
.Company_Info .Company_Info-block .item.item-space {
  margin-top: 3rem;
}
.Company_Info .Company_Info-block p {
  color: #000000;
  font-size: 14px;
  line-height: 1.6;
}
.Add_Truck {
  margin: 1rem 0;
}
.Add_Truck button {
  border: none;
  background: none;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #15198f;
  font-size: 1rem;
  padding: 0;
}
.Add_Truck button:hover {
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .Screen_MyCompany .Card .Progress-header {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .Screen_MyCompany .Card .Progress-header img {
    margin-right: 1rem;
  }
  .Screen_MyCompany .Card .Progress-header h3 {
    padding-right: 4rem;
  }
  .Company_Info {
    display: inline-flex;
    width: 100%;
  }
  .Company_Info .Company_Info-block {
    width: 50%;
  }
  .Company_Info .Company_Info-block:first-child {
    margin-right: 2rem;
    border-right: 1px solid #eeeeee;
  }
}

.Form .Form--inner form {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}
.Form .Form--inner form h5 {
  color: #424242;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
}
.Form .Form--inner form .input-collection {
  margin: 1rem 0;
}
.Form .Form--inner form .input-collection h4 {
  color: #424242;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
}
.Form .Form--inner form .input-collection .input-wrapper .input {
  display: flex;
  width: 100%;
  position: relative;
}
.Form .Form--inner form .input-collection .input-wrapper .input input,
.Form .Form--inner form .input-collection .input-wrapper .input textarea {
  width: 100%;
  border: none;
  padding: 0.5rem 0.25rem;
  font-family: "Inter", sans-serif;
  color: #050720;
  font-weight: 600;
  position: relative;
  border-bottom: 1px solid #9aabc2;
  font-size: 1rem;
  resize: none;
  height: 42.5px;
  line-height: 1.6;
  margin-bottom: -2px;
}
.Form .Form--inner form .input-collection .input-wrapper .input input::-webkit-input-placeholder, .Form .Form--inner form .input-collection .input-wrapper .input textarea::-webkit-input-placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 400;
}
.Form .Form--inner form .input-collection .input-wrapper .input input:-ms-input-placeholder, .Form .Form--inner form .input-collection .input-wrapper .input textarea:-ms-input-placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 400;
}
.Form .Form--inner form .input-collection .input-wrapper .input input::-ms-input-placeholder, .Form .Form--inner form .input-collection .input-wrapper .input textarea::-ms-input-placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 400;
}
.Form .Form--inner form .input-collection .input-wrapper .input input::placeholder,
.Form .Form--inner form .input-collection .input-wrapper .input textarea::placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 400;
}
.Form .Form--inner form .input-collection .input-wrapper .input input:focus,
.Form .Form--inner form .input-collection .input-wrapper .input textarea:focus {
  outline: none;
  border-bottom: 2px solid #277dff;
}
.Form .Form--inner form .input-collection .input-wrapper .input input:focus + .input:before,
.Form .Form--inner form .input-collection .input-wrapper .input textarea:focus + .input:before {
  height: 2px;
}
.Form .Form--inner form .input-collection .input-wrapper .input input:disabled,
.Form .Form--inner form .input-collection .input-wrapper .input textarea:disabled {
  background: #eaeaea;
}
.Form .Form--inner form .input-collection .input-wrapper .input input[type="number"],
.Form .Form--inner form .input-collection .input-wrapper .input textarea[type="number"] {
  position: relative;
}
.Form .Form--inner form .input-collection .input-wrapper .input input[type="number"]::-webkit-inner-spin-button,
.Form .Form--inner form .input-collection .input-wrapper .input textarea[type="number"]::-webkit-inner-spin-button,
.Form .Form--inner form .input-collection .input-wrapper .input input[type="number"]::-webkit-outer-spin-button,
.Form .Form--inner form .input-collection .input-wrapper .input textarea[type="number"]::-webkit-outer-spin-button {
  padding: 1rem 0.5rem;
}
.Form .Form--inner form .input-collection .input-wrapper .input input[type="number"]::-webkit-inner-spin-button:hover,
.Form .Form--inner form .input-collection .input-wrapper .input textarea[type="number"]::-webkit-inner-spin-button:hover,
.Form .Form--inner form .input-collection .input-wrapper .input input[type="number"]::-webkit-outer-spin-button:hover,
.Form .Form--inner form .input-collection .input-wrapper .input textarea[type="number"]::-webkit-outer-spin-button:hover {
  cursor: pointer;
}
.Form .Form--inner form .input-collection .input-wrapper .input input.invalid,
.Form .Form--inner form .input-collection .input-wrapper .input textarea.invalid {
  border-color: #e13505;
}
.Form .Form--inner form .input-collection .input-wrapper .input textarea {
  transition: 0.25s all ease;
}
.Form .Form--inner form .input-collection .input-wrapper .input textarea:focus {
  transition: 0.25s all ease;
  height: 150px;
}
.Form .Form--inner form .input-collection .input-wrapper .input label {
  width: 100%;
  display: inline-flex;
  align-items: center;
  color: #050720;
  font-size: 14px;
  font-weight: 500;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Form .Form--inner form .input-collection .input-wrapper .input label input {
  width: 15px;
  height: 15px;
  margin: 0;
  margin-right: 1rem;
  position: relative;
}
.Form .Form--inner form .input-collection .input-wrapper .input label input[type="radio"]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #9aabc2;
}
.Form .Form--inner form .input-collection .input-wrapper .input label input[type="radio"]:checked:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4.5px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #262a68;
}
.Form .Form--inner form .input-collection .input-wrapper .input label input:hover {
  cursor: pointer;
}
.Form .Form--inner form .input-collection .input-wrapper .input label:focus {
  outline: none;
}
.Form .Form--inner form .input-collection .input-wrapper .input label:hover {
  cursor: pointer;
}
.Form .Form--inner form .input-collection .input-wrapper .input .error {
  position: absolute;
  top: -12.5px;
  right: 15px;
  background: #fff;
  padding: 0.25rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Form .Form--inner form .input-collection .input-wrapper .input .error p {
  color: #e13505;
  font-size: 12px;
  margin: 0;
  white-space: nowrap;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control {
  padding: 0.5rem 0.25rem;
  border: none;
  border-bottom: 1px solid #9aabc2;
  outline: none;
  box-shadow: none;
  border-radius: none;
  font-weight: 600;
  border-radius: 0;
  margin-bottom: -2px;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control.react-select__control--is-focused {
  border-bottom: 2px solid #277dff;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control .react-select__value-container {
  margin-bottom: -2px;
  padding: 0;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control .react-select__single-value {
  margin: 0;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control .react-select__indicator {
  padding: 0 8px;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control .react-select__placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 400;
}
.Form .Form--inner form .inline-input {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
}
.Form .Form--inner form .inline-input .input-collection {
  margin-right: 2rem;
}
.Form .Form--inner form .textarea-counter {
  margin-bottom: 1.5rem;
  position: relative;
}
.Form .Form--inner form .textarea-counter p {
  position: absolute;
  right: 0;
  bottom: -17.5px;
  margin: 0;
  font-size: 11px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Form .Form--inner form .dropzone {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.Form .Form--inner form .dropzone:focus {
  outline: none;
}
.Form .Form--inner form .dropzone p {
  margin: 0;
  color: #686a7d;
  font-size: 14px;
  margin-right: 1rem;
}
.Form .Form--inner form .dropzone:hover {
  cursor: pointer;
}
.Form .Form--inner form .dropdown {
  width: 100%;
}
.Form .Form--inner form .dropdown.invalid .react-select__control {
  border-color: #e13505;
}
.Form .Form--inner form .avatar-preview img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
}
.Form .Form--inner form .Form--block {
  margin: 2rem 0;
  background: #fff;
  border-radius: 7px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 3px 30px 0px rgba(151, 180, 203, 0.16);
  padding: 1rem;
}
.Form .Form--inner form .Form--block h3 {
  font-size: 14px;
  color: #277dff;
  font-weight: 500;
  margin: 0;
  margin-bottom: 2rem;
}
.Form .Form--inner form .Form--block .error p {
  color: #e13505;
  font-size: 14px;
}
.Form .Form--inner form input[type="submit"] {
  background: none;
  border: 2px solid #262a68;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: #262a68;
  font-weight: 700;
  padding: 1.25rem 2.75rem;
  font-size: 1rem;
  transition: 0.25s all ease;
  margin: 1.5rem 0;
  background: #fff;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.Form .Form--inner form input[type="submit"]:focus,
.Form .Form--inner form input[type="submit"]:hover {
  transition: 0.25s all ease;
  outline: none;
  background: #262a68;
  color: #fff;
}
.Form .Form--inner form input[type="submit"]:hover {
  cursor: pointer;
}
.Form .Form--inner form input[type="submit"].success {
  border: 2px solid #2bbf8d;
  color: #2bbf8d;
}
.Form .Form--inner form input[type="submit"].success:hover {
  background: #2bbf8d;
  color: #fff;
}
@media screen and (min-width: 992px) {
  .Form .Form--inner {
    background: #fff;
    border-radius: 7px;
    padding: 2rem 0;
  }
  .Form .Form--inner form .Form--block {
    box-shadow: none;
    border: none;
    padding: 0;
  }
  .Form .Form--inner form .Form--block h3 {
    display: flex;
    margin: 2rem 0;
    margin-left: 35%;
  }
  .Form .Form--inner form .input-collection {
    margin: 1.5rem 0;
  }
  .Form .Form--inner form .input-collection .input-wrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .Form .Form--inner form .input-collection .input-wrapper h4 {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    width: 50%;
    text-align: right;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input input,
  .Form .Form--inner form .input-collection .input-wrapper .input textarea {
    border: 1px solid #9aabc2;
    border-radius: 6px;
    padding: 0.5rem;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input input:focus,
  .Form .Form--inner form .input-collection .input-wrapper .input textarea:focus {
    transition: 0.25s all ease;
    outline: none;
    box-shadow: 0 0 0 2px #a0d1fa;
    border-bottom: 1px solid #9aabc2;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea {
    overflow-y: hidden;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea:focus {
    overflow-y: auto;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea::-webkit-scrollbar-track {
    background-color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea::-webkit-scrollbar {
    background-color: #fff;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea::-webkit-scrollbar-thumb {
    background-color: #c5c6d3;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea ~ .error p {
    background: #fff;
    padding: 0.25rem;
  }
  .Form .Form--inner form .input-collection .input-wrapper .react-select__control {
    border: 1px solid #9aabc2;
    border-radius: 6px;
    padding: 0.5rem;
  }
  .Form .Form--inner form .input-collection .input-wrapper .react-select__control.react-select__control--is-focused {
    transition: 0.25s all ease;
    outline: none;
    box-shadow: 0 0 0 2px #a0d1fa;
    border-bottom: 1px solid #9aabc2;
  }
  .Form .Form--inner form .inline-input {
    flex-direction: row;
  }
  .Form .Form--inner form .inline-input h5 {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    align-items: center;
    width: 50%;
    text-align: right;
  }
  .Form .Form--inner form input[type="submit"] {
    max-width: 50%;
    margin: 3rem auto;
  }
  .Form .Form--inner form input[type="submit"]:focus {
    transition: 0.25s all ease;
    outline: none;
    box-shadow: 0 0 0 2px #a0d1fa;
  }
}

.Messages .Messages--inner .Current_Message_Window {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 35;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header {
  box-shadow: 0px 3px 3px 0px rgba(117, 141, 187, 0.14);
  height: 56px;
  background: #fff;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 1rem 3.5rem;
  position: relative;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Back {
  position: absolute;
  left: 0;
  top: 0;
  height: inherit;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-image img {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  object-fit: contain;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-info {
  padding-left: 1rem;
  padding-right: 3rem;
  width: 100%;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-info h2 {
  margin: 0;
  font-size: 14px;
  height: calc(56px - 1.2rem);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: #000000;
  line-height: 1.4;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub {
  display: none;
  flex-direction: row;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub h4,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub a {
  color: #686a7d;
  margin: 0;
  font-size: 12px;
  margin-right: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub h4 {
  font-weight: 500;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub a {
  font-weight: 400;
  text-decoration: underline;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Buttons {
  display: inline-flex;
  position: absolute;
  right: 1rem;
  height: inherit;
  align-items: center;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Buttons img {
  margin: 0 0.5rem;
  width: 20px;
  height: inherit;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box {
  height: calc(100vh - 56px);
  position: relative;
  background: #fff;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner {
  padding: 0 1.75rem 1rem 1.75rem;
  height: calc(100vh - 56px - 76px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
  transition: 0.25s height ease;
  min-height: 25vh;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever {
  padding: 0.5rem 0.75rem;
  display: flex;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  border-radius: 15px;
  position: relative;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender h1,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever h1,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender h2,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever h2,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender h3,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever h3,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender h4,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever h4,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender h5,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever h5,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender p,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever p,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender span,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever span,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender li,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever li,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender a,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever a {
  margin: 0;
  line-height: 1.4;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
  word-break: break-word;
  width: 100%;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender a,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever a {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #66a1f9;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender strong,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever strong {
  font-weight: 600;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender:hover:before,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever:hover:before {
  content: attr(data-created);
  position: absolute;
  top: -21px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.1rem 0.25rem;
  border-radius: 3px;
  line-height: 1.5;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  font-size: 12px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever {
  background: #eef9f5;
  margin-right: auto;
  position: relative;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 2px auto 2px 1.5rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever img {
  display: flex;
  align-items: center;
  max-width: 300px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender {
  background: #d5f1fe;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 2px 0 2px auto;
  position: relative;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender img {
  display: flex;
  align-items: center;
  max-width: 300px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender .sender-seen {
  position: absolute;
  bottom: -0.5rem;
  right: -1rem;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message {
  position: relative;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] {
  margin-top: 2.5rem;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] .sender {
  border-top-right-radius: 15px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] .reciever {
  border-top-left-radius: 15px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message[data-end] .sender {
  border-bottom-right-radius: 15px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message[data-end] .reciever {
  border-bottom-left-radius: 15px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message .reciever-avatar {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  object-fit: contain;
  position: absolute;
  left: -12px;
  bottom: 4px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message .seen {
  position: absolute;
  right: -17.5px;
  bottom: 0;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 30;
  background: #fff;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner {
  position: relative;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box {
  min-height: 76px;
  display: inline-flex;
  width: 100%;
  border-top: 1px solid #dce1eb;
  border-radius: 0;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-toolbar {
  border: none;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-newline,
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .second-toolbar {
  display: none;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-btn-grp {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0.75rem;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-wrapper {
  width: 100%;
  border: none;
  margin: 0.5rem 0.75rem;
  background: #f5f5f5;
  border-radius: 10px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  min-height: 46px;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-element {
  padding-top: 0.75rem !important;
  padding-right: 2.75rem !important;
  max-height: 400px;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-element::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-element::-webkit-scrollbar {
  background-color: #eaeaea;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-element::-webkit-scrollbar-thumb {
  background-color: #c5c6d3;
  border: 6px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  min-height: 70px;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-element p {
  word-break: break-word;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-toolbar .fr-popup {
  left: 42px !important;
  top: unset !important;
  bottom: 76px;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box textarea {
  font-size: 16px;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .Send_Icon {
  position: fixed;
  bottom: 2rem;
  z-index: 90;
  right: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fr-popup .fr-buttons {
  display: none !important;
}
@media screen and (min-width: 992px) {
  .Messages {
    margin-left: 84px;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message {
    left: 85px;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-wrapper {
    margin-right: 6rem;
  }
}
@media screen and (min-width: 1200px) {
  .Messages .Messages--inner {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
  }
  .Messages .Messages--inner .Current_Message_Window {
    background: #fff;
    max-width: calc(100% - 237px - 84px - 320px);
    position: relative;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header {
    height: 106px;
    box-shadow: none;
    border-bottom: 1px solid #dce1eb;
    padding: 0.5rem;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Back,
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Buttons {
    display: none;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-image img {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-info {
    padding-right: 3rem;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-info h2 {
    font-size: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 1rem;
    display: block;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub {
    display: flex;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Buttons img {
    width: 25px;
    height: 25px;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Buttons img:hover {
    cursor: pointer;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box {
    height: calc(100vh - 56px - 129px);
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner {
    height: calc(100vh - 56px - 129px);
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar-track {
    background-color: #fff;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar {
    background-color: #eaeaea;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar-thumb {
    background-color: #c5c6d3;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
    min-height: 70px;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message {
    left: 404px;
    max-width: calc(100% - 320px - 84px - 320px);
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-wrapper {
    margin-right: 0.5rem;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .Send_Icon {
    position: absolute;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .Send_Icon:hover {
    cursor: pointer;
  }
  .Messages .Messages--inner .Tender_Modal {
    max-width: 320px;
    box-shadow: 0px -10px 15px 0px rgba(154, 171, 194, 0.3);
  }
  .Messages .Messages--inner .Tender_Modal::-webkit-scrollbar-track {
    background-color: #fff;
  }
  .Messages .Messages--inner .Tender_Modal::-webkit-scrollbar {
    background-color: #eaeaea;
  }
  .Messages .Messages--inner .Tender_Modal::-webkit-scrollbar-thumb {
    background-color: #c5c6d3;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  .Messages .Messages--inner .Tender_Modal .modal-head .modal-close {
    display: none;
  }
}
.Messages .Tender_Modal .Start_Conversation {
  display: none;
}
@media screen and (min-width: 1500px) {
  .Messages .Messages--inner .Current_Message_Window {
    max-width: calc(100% - 316px - 84px - 280px);
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Tender_Modal {
    max-width: 360px;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message {
    left: 444px;
    max-width: calc(100% - 400px - 84px - 280px);
  }
  .Messages .Messages--inner .Roundtrip_Modal {
    max-width: 320px;
  }
}
.Messages .Mini_Messages {
  display: none !important;
}
@media (max-width: 992px) {
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner {
    padding-bottom: calc(35.2px + 1rem);
  }
}

.Sub_Menu {
  display: flex;
  align-items: center;
  background: #fbfbfc;
  margin-top: 64px;
  left: -1rem;
  position: relative;
  width: calc(100% + 2rem);
}
.Sub_Menu .Sub_Menu--inner {
  display: inline-flex;
  width: 100%;
  height: 60px;
  padding-top: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
}
.Sub_Menu .Sub_Menu--inner a {
  white-space: nowrap;
  padding: 0 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0 1.25rem;
  text-decoration: none;
  position: relative;
  color: #050720;
  font-weight: 500;
}
.Sub_Menu .Sub_Menu--inner a:hover {
  color: #7d7f90;
}
.Sub_Menu .Sub_Menu--inner a.active {
  color: #050720;
}
.Sub_Menu .Sub_Menu--inner a.active:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: #e13505;
}
.Sub_Menu .Sub_Menu--inner::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
@media screen and (min-width: 992px) {
  .Sub_Menu {
    background: none;
    margin-top: 0;
    left: unset;
    width: 100%;
  }
  .Sub_Menu .Sub_Menu--inner {
    justify-content: center;
    align-items: center;
    max-width: 795px;
    background: #fff;
    box-shadow: 0px 3px 27px 0px rgba(151, 180, 203, 0.16);
    border: 1px solid #eeeeee;
    margin: auto;
    border-radius: 10px;
    padding: 3rem 0 2.5rem;
  }
  .Sub_Menu .Sub_Menu--inner a:not(:last-child):after {
    content: "";
    position: absolute;
    right: -20px;
    top: 0;
    height: calc(100% - 0.5rem);
    width: 1.5px;
    background: #707070;
  }
}
@media screen and (min-width: 1200px) {
  .Sub_Menu .Sub_Menu--inner {
    max-width: 650px;
  }
}
@media screen and (min-width: 1400px) {
  .Sub_Menu .Sub_Menu--inner {
    max-width: 795px;
  }
}
@media screen and (min-width: 1600px) {
  .Sub_Menu .Sub_Menu--inner {
    max-width: 970px;
  }
}
@media screen and (min-width: 1800px) {
  .Sub_Menu .Sub_Menu--inner {
    max-width: 1170px;
  }
}

.Table {
  display: flex;
}
.Table .Table--inner {
  width: 100%;
}
.Table .Table--inner .Table-head {
  padding: 1rem 0;
  width: 100%;
}
.Table .Table--inner .Table-head h4 {
  margin: 0;
  color: #686a7d;
  font-size: 14px;
  font-weight: 400;
}
.Table .Table--inner .Table-head.only-desktop {
  display: none !important;
}
.Table .Table--inner .Table-content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.Table .Table--inner .Table-content .Table-row {
  padding: 1rem;
  margin: 2rem 0;
  position: relative;
  background: #fff;
  border-radius: 7px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 3px 30px 0px rgba(151, 180, 203, 0.16);
}
.Table .Table--inner .Table-content .Table-row .item {
  padding: 0.75rem 0;
}
.Table .Table--inner .Table-content .Table-row .item:first-child {
  padding-top: 1.5rem;
}
.Table .Table--inner .Table-content .Table-row .item h4,
.Table .Table--inner .Table-content .Table-row .item h5,
.Table .Table--inner .Table-content .Table-row .item h6 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  width: 100%;
}
.Table .Table--inner .Table-content .Table-row .item h4,
.Table .Table--inner .Table-content .Table-row .item a {
  font-size: 1rem;
  font-weight: 500;
  color: #010101;
}
.Table .Table--inner .Table-content .Table-row .item a {
  text-decoration: none;
}
.Table .Table--inner .Table-content .Table-row .item h5 {
  color: #686a7d;
}
.Table .Table--inner .Table-content .Table-row .item h6.only-mobile {
  font-size: 12px;
  color: #686a7d;
  text-transform: uppercase;
}
.Table .Table--inner .Table-content .Table-row .item .options {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  width: 35px;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Table .Table--inner .Table-content .Table-row .item .options:hover {
  cursor: pointer;
}
.Table .Table--inner .Table-content .Table-row .item .options img {
  pointer-events: none;
}
.Table .Table--inner .Table-content .Table-row .item .options.false .options-dropdown {
  display: none;
}
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown {
  position: absolute;
  top: 1.5rem;
  right: 0;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.16);
  background: #fff;
}
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown ul li,
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown ul a {
  list-style-type: none;
  padding: 1rem 2rem;
  white-space: nowrap;
}
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown ul li:hover,
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown ul a:hover {
  background: #f9f9f9;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status {
  color: #fff;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 25px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.75rem;
  padding-left: 1.75rem;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 0.5rem;
  top: 0.5rem;
  border-radius: 50%;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status.active {
  background: #3ad59b;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status.active:before {
  background: #27a590;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status.inactive {
  background: #f9f9f9;
  color: #000;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status.inactive:before {
  background: #eaeaea;
}
.Table .Table--inner .Table-content .Table-row .item .toggler {
  color: #686a7d;
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.Table .Table--inner .Table-content .Table-row .item .toggler .toggler-button {
  margin-right: 1rem;
  width: 50px;
  height: 25px;
  background: #b2b2b2;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.Table .Table--inner .Table-content .Table-row .item .toggler .toggler-button:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #b2b2b2;
  transition: 0.25s all ease;
}
.Table .Table--inner .Table-content .Table-row .item .toggler input {
  width: 50px;
  height: 25px;
  margin: 0;
  position: absolute;
  z-index: 1;
  opacity: 0;
}
.Table .Table--inner .Table-content .Table-row .item .toggler input:checked ~ .toggler-button {
  background: #2bbf8d;
}
.Table .Table--inner .Table-content .Table-row .item .toggler input:checked ~ .toggler-button:before {
  transform: translateX(100%);
  left: unset;
  transition: 0.25s all ease;
}
.Table .Table--inner .Table-content .Table-row .item .toggler input:hover {
  cursor: pointer;
}
hr {
  border: none;
  height: 1px;
  background: #dcdcdc;
}
.Table_Modal--close {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 19;
  background: rgba(0, 0, 0, 0.4);
}
.Table_Modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.16);
  padding: 1rem;
  z-index: 20;
  pointer-events: none;
  display: flex;
  margin: auto;
}
.Table_Modal .Table_Modal--inner {
  position: relative;
  pointer-events: all;
  width: 100%;
  max-height: 100%;
  background: #fff;
  overflow-y: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.Table_Modal .Table_Modal--inner .Table_Modal--header {
  padding: 1rem;
  display: flex;
  width: 100%;
  background: #f9f9f9;
  justify-content: center;
  align-items: center;
}
.Table_Modal .Table_Modal--inner .Table_Modal--header h2 {
  font-size: 1rem;
  color: #262a68;
  text-align: center;
  line-height: 1.6;
  padding-right: 3.5rem;
  margin: 0;
  font-weight: 600;
}
.Table_Modal .Table_Modal--inner .Table_Modal--header .Table_Modal--inner-close {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.25s all ease;
  border: 1px solid #eaeaea;
}
.Table_Modal .Table_Modal--inner .Table_Modal--header .Table_Modal--inner-close:hover {
  cursor: pointer;
  background: #eaeaea;
  transition: 0.25s all ease;
}
.Table_Modal .Table_Modal--inner .Table_Modal--body {
  padding: 2rem 1rem;
}
.Table_Modal .Table_Modal--inner .Table_Modal--body img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem auto;
  max-height: 25vh;
}
.Table_Modal .Table_Modal--inner .Table_Modal--body h3,
.Table_Modal .Table_Modal--inner .Table_Modal--body h4 {
  color: #050720;
  margin: 0;
  text-align: center;
  line-height: 1.6;
}
.Table_Modal .Table_Modal--inner .Table_Modal--body h3 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.6;
}
.Table_Modal .Table_Modal--inner .Table_Modal--body h4 {
  font-size: 14px;
  font-weight: 400;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer {
  padding: 1rem;
  background: #f9f9f9;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button {
  background: none;
  border: none;
  color: #050720;
  font-family: "Inter", sans-serif;
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  font-weight: 600;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button:first-child {
  position: relative;
  padding: 0;
  margin: 1rem 2rem;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button:first-child:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #050720;
  height: 2px;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button:last-child {
  background: #3ad59b;
  color: #fff;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button:hover {
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .Table_Modal {
    display: inline-flex;
    width: 100%;
    padding: 2.5rem 4rem;
  }
  .Table_Modal .Table_Modal--inner {
    max-width: 992px;
  }
}
@media screen and (min-width: 1400px) {
  .Table {
    max-width: 100%;
  }
  .Table .Table--inner .Table-head {
    display: flex;
  }
  .Table .Table--inner .Table-head h4 {
    text-transform: uppercase;
    font-size: 13px;
    flex: 0.5 0.5;
  }
  .Table .Table--inner .Table-head h4:nth-child(1) {
    flex: 1 1;
  }
  .Table .Table--inner .Table-head h4:last-child {
    text-align: right;
  }
  .Table .Table--inner .Table-head h4:first-child {
    text-align: left;
  }
  .Table .Table--inner .Table-head.only-desktop {
    display: inline-flex !important;
  }
  .Table .Table--inner .Table-content .Table-row {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 1rem 0 !important;
    margin: 0;
    border: none;
    box-shadow: none;
  }
  .Table .Table--inner .Table-content .Table-row .item {
    display: flex;
    flex: 0.5 0.5;
    font-size: 14px;
    flex-direction: column;
    width: 0;
    padding: 0;
    background: unset;
  }
  .Table .Table--inner .Table-content .Table-row .item:first-child {
    padding: 0;
  }
  .Table .Table--inner .Table-content .Table-row .item:nth-child(1) {
    flex: 1 1;
  }
  .Table .Table--inner .Table-content .Table-row .item h6.only-mobile {
    display: none;
  }
  .Table .Table--inner .Table-content .Table-row .item h4,
  .Table .Table--inner .Table-content .Table-row .item h5,
  .Table .Table--inner .Table-content .Table-row .item h6 {
    margin: 0;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 2rem;
  }
  .Table .Table--inner .Table-content .Table-row .item h4 {
    font-size: 1rem;
  }
  .Table .Table--inner .Table-content .Table-row .item h4:last-child {
    text-align: left !important;
  }
  .Table .Table--inner .Table-content .Table-row .item h5 {
    color: #686a7d;
  }
  .Table .Table--inner .Table-content .Table-row .item:last-child {
    align-items: flex-end;
  }
  .Table .Table--inner .Table-content .Table-row .item:first-child {
    align-items: flex-start;
  }
  .Table .Table--inner .Table-content .Table-row .item .options {
    position: relative;
    top: unset;
  }
  .Table .Table--inner .Table-content .Table-row .item .options .options-dropdown {
    z-index: 1;
  }
  .Table_Modal {
    display: inline-flex;
    width: 100%;
    padding: 5rem 8rem;
  }
  .Table_Modal .Table_Modal--inner {
    display: inline-flex;
    width: 100%;
    margin: auto;
    max-width: 1140px;
  }
  .Table_Modal .Table_Modal--inner h3,
  .Table_Modal .Table_Modal--inner h4 {
    margin-bottom: 0.25rem;
  }
  .Table_Modal .Table_Modal--inner .Table_Modal--header h2 {
    padding: 0;
  }
  .Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons {
    width: 100%;
    justify-content: center;
  }
  .Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button {
    padding: 1rem 2rem;
    margin: 0 1rem;
    transition: 0.25s all ease;
  }
  .Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button:last-child:hover {
    transition: 0.25s all ease;
    background: #41e9ab;
  }
}
@media screen and (min-width: 1600px) {
  .Table {
    padding: 1rem;
  }
}


#card-number,
#cvv,
#expiration-date {
  height: 42.5px;
  border: 1px solid #9aabc2;
  border-radius: 6px;
  padding: 0.5rem;
}
#card-number.braintree-hosted-fields-invalid,
#cvv.braintree-hosted-fields-invalid,
#expiration-date.braintree-hosted-fields-invalid {
  border: 1px solid #e13505;
}
#card-number.braintree-hosted-fields-valid,
#cvv.braintree-hosted-fields-valid,
#expiration-date.braintree-hosted-fields-valid {
  color: #3ad59b;
  border: 1px solid #3ad59b;
}
#cardDetailsForm input::-webkit-input-placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 300;
}
#cardDetailsForm input:-ms-input-placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 300;
}
#cardDetailsForm input::-ms-input-placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 300;
}
#cardDetailsForm input::placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 300;
}

.Form .Form--inner form .Select_File {
  display: inline-flex;
  width: 100%;
}
.Form .Form--inner form .Select_File h4 {
  color: #424242;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
}
.Form .Form--inner form .Select_File .dropzone {
  display: flex;
  justify-content: flex-start;
}
.Form .Form--inner form .Select_File .dropzone p {
  padding: 0.5rem 1rem;
  height: 42.5px;
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
  color: #b0b0b5;
  border: 1px solid #9aabc2;
  border-radius: 6px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Form .Form--inner form .Files ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
.Form .Form--inner form .Files ul li {
  padding: 2rem 0;
  list-style: none;
}
@media screen and (min-width: 992px) {
  .Form .Form--inner form .Select_File h4 {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    width: 50%;
  }
  .Form .Form--inner form .Select_File .dropzone p {
    width: auto;
  }
  .Form .Form--inner form .Files ul {
    justify-content: unset;
    margin-left: 157px;
  }
}

.Notifications form {
  max-width: 360px;
}
.Notifications form h3 {
  color: #686a7d;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 2rem;
}
.Notifications form .toggle-wrapper label {
  display: inline-flex;
  width: 100%;
  margin: 1rem 0;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  justify-content: space-between;
  color: #050720;
}
.Notifications form .toggle-wrapper label .toggler {
  width: 50px;
  height: 25px;
  background: #b2b2b2;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.Notifications form .toggle-wrapper label .toggler:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #b2b2b2;
  transition: 0.25s all ease;
}
.Notifications form .toggle-wrapper label input {
  display: none;
}
.Notifications form .toggle-wrapper label input:checked ~ .toggler {
  background: #2bbf8d;
}
.Notifications form .toggle-wrapper label input:checked ~ .toggler:before {
  transform: translateX(100%);
  left: unset;
  transition: 0.25s all ease;
}
.Notifications form .toggle-wrapper label input:hover {
  cursor: pointer;
}
.Notifications form .toggle-wrapper label:hover {
  cursor: pointer;
}
.Notifications .Form .Form--inner form .Form--block h3 {
  margin: 1.5rem 0;
}
@media screen and (min-width: 992px) {
  .Notifications .Form .Form--inner form .Form--block {
    margin: 0 3rem;
  }
  .Notifications .Form .Form--inner form .Form--block h3 {
    justify-content: center;
    align-items: center;
  }
}

.Single_Company .Single_Company--wrapper {
  background: #fff;
  box-shadow: 0px 3px 27px 0px rgba(151, 180, 203, 0.16);
  border: 1px solid #eeeeee;
  padding: 1rem;
  border-radius: 7px;
}
.Single_Company .Single_Company--wrapper .Single_Company--header {
  display: inline-flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
  flex-direction: column;
  align-items: flex-start;
}
.Single_Company .Single_Company--wrapper .Single_Company--header img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  object-fit: contain;
  margin-right: 1rem;
  border-radius: 50%;
}
.Single_Company .Single_Company--wrapper .Single_Company--header h3 {
  margin: 0;
  color: #262a68;
  font-size: 1.25rem;
}
.Single_Company .Single_Company--wrapper .Single_Company--header div:first-child {
  display: inline-flex;
  align-items: center;
}
.Single_Company .Single_Company--wrapper .Single_Company--header div.s {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.5rem;
}
@media (min-width: 992px) {
  .Single_Company .Single_Company--wrapper .Single_Company--header {
    flex-direction: row;
    justify-content: space-between;
  }
}
.Single_Company .Single_Company--wrapper .Single_Company--content {
  margin: 1rem 0;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item {
  margin: 1rem 0;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item h4,
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item h5 {
  margin: 0;
  margin-bottom: 0.25rem;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item h4 {
  color: #000000;
  font-weight: 600;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item a {
  color: #000000;
  text-decoration: none;
  font-size: 14px;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item h5 {
  color: #050720;
  font-weight: 500;
  font-size: 14px;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block p {
  line-height: 1.6;
  color: #000000;
  margin: 1.5rem 0;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .Send_Message {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .Send_Message a {
  padding: 1rem 2rem;
  border: 2px solid #262a68;
  border-radius: 4px;
  color: #262a68;
  font-weight: 600;
  text-decoration: none;
  transition: 0.25s all ease;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .Send_Message a:hover {
  transition: 0.25s all ease;
  background: #262a68;
  color: #fff;
}
@media screen and (min-width: 992px) {
  .Single_Company .Single_Company--wrapper .Single_Company--header img {
    width: 88px;
    min-width: 88px;
    height: 88px;
    margin-right: 1.5rem;
    object-fit: contain;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--header h3 {
    font-size: 1.5rem;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content {
    display: inline-flex;
    width: 100%;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block {
    width: 50%;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item {
    display: inline-flex;
    width: 100%;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item h4 {
    width: 250px;
    padding-right: 2.5rem;
    text-align: right;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item:nth-child(2),
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item:nth-child(4) {
    margin-bottom: 2.5rem;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block:last-child {
    padding-left: 1rem;
  }
}
@media screen and (min-width: 1400px) {
  .Card .Table .Table--inner .Table-content .Table-row .item:nth-child(even) h4:last-child {
    text-align: right;
  }
  .Card .Table .Table--inner .Table-content .Table-row .item:nth-child(even) h4:first-child {
    text-align: left;
  }
}

