.Messages .Messages--inner .Current_Message_Window {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 35;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header {
  box-shadow: 0px 3px 3px 0px rgba(117, 141, 187, 0.14);
  height: 56px;
  background: #fff;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 1rem 3.5rem;
  position: relative;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Back {
  position: absolute;
  left: 0;
  top: 0;
  height: inherit;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-image img {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  object-fit: contain;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-info {
  padding-left: 1rem;
  padding-right: 3rem;
  width: 100%;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-info h2 {
  margin: 0;
  font-size: 14px;
  height: calc(56px - 1.2rem);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: #000000;
  line-height: 1.4;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub {
  display: none;
  flex-direction: row;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub h4,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub a {
  color: #686a7d;
  margin: 0;
  font-size: 12px;
  margin-right: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub h4 {
  font-weight: 500;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub a {
  font-weight: 400;
  text-decoration: underline;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Buttons {
  display: inline-flex;
  position: absolute;
  right: 1rem;
  height: inherit;
  align-items: center;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Buttons img {
  margin: 0 0.5rem;
  width: 20px;
  height: inherit;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box {
  height: calc(100vh - 56px);
  position: relative;
  background: #fff;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner {
  padding: 0 1.75rem 1rem 1.75rem;
  height: calc(100vh - 56px - 76px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  transition: 0.25s height ease;
  min-height: 25vh;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever {
  padding: 0.5rem 0.75rem;
  display: flex;
  max-width: max-content;
  border-radius: 15px;
  position: relative;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender h1,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever h1,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender h2,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever h2,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender h3,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever h3,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender h4,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever h4,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender h5,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever h5,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender p,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever p,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender span,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever span,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender li,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever li,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender a,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever a {
  margin: 0;
  line-height: 1.4;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
  word-break: break-word;
  width: 100%;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender a,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever a {
  width: max-content;
  color: #66a1f9;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender strong,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever strong {
  font-weight: 600;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender:hover:before,
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever:hover:before {
  content: attr(data-created);
  position: absolute;
  top: -21px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.1rem 0.25rem;
  border-radius: 3px;
  line-height: 1.5;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  font-size: 12px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever {
  background: #eef9f5;
  margin-right: auto;
  position: relative;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 2px auto 2px 1.5rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .reciever img {
  display: flex;
  align-items: center;
  max-width: 300px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender {
  background: #d5f1fe;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 2px 0 2px auto;
  position: relative;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender img {
  display: flex;
  align-items: center;
  max-width: 300px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sender .sender-seen {
  position: absolute;
  bottom: -0.5rem;
  right: -1rem;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message {
  position: relative;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] {
  margin-top: 2.5rem;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] .sender {
  border-top-right-radius: 15px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] .reciever {
  border-top-left-radius: 15px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message[data-end] .sender {
  border-bottom-right-radius: 15px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message[data-end] .reciever {
  border-bottom-left-radius: 15px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message .reciever-avatar {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  object-fit: contain;
  position: absolute;
  left: -12px;
  bottom: 4px;
}
.Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner .sent-message .seen {
  position: absolute;
  right: -17.5px;
  bottom: 0;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 30;
  background: #fff;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner {
  position: relative;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box {
  min-height: 76px;
  display: inline-flex;
  width: 100%;
  border-top: 1px solid #dce1eb;
  border-radius: 0;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-toolbar {
  border: none;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-newline,
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .second-toolbar {
  display: none;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-btn-grp {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0.75rem;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-wrapper {
  width: 100%;
  border: none;
  margin: 0.5rem 0.75rem;
  background: #f5f5f5;
  border-radius: 10px;
  height: max-content;
  min-height: 46px;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-element {
  padding-top: 0.75rem !important;
  padding-right: 2.75rem !important;
  max-height: 400px;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-element::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-element::-webkit-scrollbar {
  background-color: #eaeaea;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-element::-webkit-scrollbar-thumb {
  background-color: #c5c6d3;
  border: 6px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  min-height: 70px;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-element p {
  word-break: break-word;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-toolbar .fr-popup {
  left: 42px !important;
  top: unset !important;
  bottom: 76px;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box textarea {
  font-size: 16px;
}
.Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .Send_Icon {
  position: fixed;
  bottom: 2rem;
  z-index: 90;
  right: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fr-popup .fr-buttons {
  display: none !important;
}
@media screen and (min-width: 992px) {
  .Messages {
    margin-left: 84px;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message {
    left: 85px;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-wrapper {
    margin-right: 6rem;
  }
}
@media screen and (min-width: 1200px) {
  .Messages .Messages--inner {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
  }
  .Messages .Messages--inner .Current_Message_Window {
    background: #fff;
    max-width: calc(100% - 237px - 84px - 320px);
    position: relative;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header {
    height: 106px;
    box-shadow: none;
    border-bottom: 1px solid #dce1eb;
    padding: 0.5rem;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Back,
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Buttons {
    display: none;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-image img {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-info {
    padding-right: 3rem;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-info h2 {
    font-size: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 1rem;
    display: block;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Messager-sub {
    display: flex;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Buttons img {
    width: 25px;
    height: 25px;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Window--header .Buttons img:hover {
    cursor: pointer;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box {
    height: calc(100vh - 56px - 129px);
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner {
    height: calc(100vh - 56px - 129px);
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar-track {
    background-color: #fff;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar {
    background-color: #eaeaea;
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar-thumb {
    background-color: #c5c6d3;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
    min-height: 70px;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message {
    left: 404px;
    max-width: calc(100% - 320px - 84px - 320px);
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .fr-box .fr-wrapper {
    margin-right: 0.5rem;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .Send_Icon {
    position: absolute;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message .Send_Message--inner .Send_Icon:hover {
    cursor: pointer;
  }
  .Messages .Messages--inner .Tender_Modal {
    max-width: 320px;
    box-shadow: 0px -10px 15px 0px rgba(154, 171, 194, 0.3);
  }
  .Messages .Messages--inner .Tender_Modal::-webkit-scrollbar-track {
    background-color: #fff;
  }
  .Messages .Messages--inner .Tender_Modal::-webkit-scrollbar {
    background-color: #eaeaea;
  }
  .Messages .Messages--inner .Tender_Modal::-webkit-scrollbar-thumb {
    background-color: #c5c6d3;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  .Messages .Messages--inner .Tender_Modal .modal-head .modal-close {
    display: none;
  }
}
.Messages .Tender_Modal .Start_Conversation {
  display: none;
}
@media screen and (min-width: 1500px) {
  .Messages .Messages--inner .Current_Message_Window {
    max-width: calc(100% - 316px - 84px - 280px);
  }
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Tender_Modal {
    max-width: 360px;
  }
  .Messages .Messages--inner .Current_Message_Window .Send_Message {
    left: 444px;
    max-width: calc(100% - 400px - 84px - 280px);
  }
  .Messages .Messages--inner .Roundtrip_Modal {
    max-width: 320px;
  }
}
.Messages .Mini_Messages {
  display: none !important;
}
@media (max-width: 992px) {
  .Messages .Messages--inner .Current_Message_Window .Current_Message_Window--inner .Current_Message_Box .Current_Message_Box--inner {
    padding-bottom: calc(35.2px + 1rem);
  }
}
