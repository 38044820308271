.Login {
  display: flex;
  width: 100%;
  margin-top: 64px;
  background: #ffffff;
  background: -moz-linear-gradient(0deg, #ffffff 0%, #f4f8fc 50%);
  background: -webkit-linear-gradient(0deg, #ffffff 0%, #f4f8fc 50%);
  background: linear-gradient(0deg, #ffffff 0%, #f4f8fc 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f4f8fc", GradientType=1);
}
.Login .Login--inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 2rem;
}
.Login .Login--inner h2 {
  color: #262a68;
  font-size: 2rem;
  margin-bottom: 0;
}
.Login .Login--inner h4 {
  color: #5d5d5d;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-weight: 400;
}
.Login .Login--inner form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 7.5% 10%;
}
.Login .Login--inner form .floating-label {
  width: 100%;
  position: relative;
}
.Login .Login--inner form .floating-label input {
  display: flex;
  width: 100%;
  padding: 1rem;
  padding-top: 1.35rem;
  font-family: "Inter", sans-serif;
  background: #fff;
  border: 1px solid #ececec;
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #000;
  transition: 0.25s all ease;
}
.Login .Login--inner form .floating-label input:focus ~ label,
.Login .Login--inner form .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.7rem;
  left: 1rem;
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Login .Login--inner form .floating-label input:focus {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.Login .Login--inner form .floating-label label {
  color: #9a9a9a;
  transition: 0.25s all ease;
  position: absolute;
  left: 1rem;
  top: 1.6rem;
  pointer-events: none;
}
.Login .Login--inner form .inline-label {
  display: inline-flex;
  white-space: nowrap;
}
.Login .Login--inner form .inline-label label {
  cursor: pointer;
  user-select: none;
}
.Login .Login--inner form .inline-label input[type="checkbox"] {
  position: relative;
  transform: translateX(-4px);
  cursor: pointer;
}
.Login .Login--inner form .inline-label input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 15.5px;
  height: 15px;
  top: 0;
  left: 0;
  border: 1.5px solid #000;
  border-radius: 3px;
  background-color: #fff;
}
.Login .Login--inner form .inline-label input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1.5px;
  left: 5.25px;
}
.Login .Login--inner form a {
  color: #262a68;
  text-decoration: none;
  font-size: 14px;
  margin-left: auto;
  text-align: right;
  outline: none;
  padding: 2px;
  transition: 0.25s all ease;
}
.Login .Login--inner form a:hover,
.Login .Login--inner form a:focus {
  color: #000;
  transition: 0.25s all ease;
}
.Login .Login--inner form .Submit__Form {
  position: relative;
  width: fit-content;
}
.Login .Login--inner form .Submit__Form input[type="submit"] {
  font-family: "Inter", sans-serif;
  background: #000;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 0.75rem 4rem;
  border-radius: 10rem;
  text-transform: uppercase;
  margin-left: auto;
  margin-top: 2rem;
  transition: 0.25s all ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.Login .Login--inner form .Submit__Form input[type="submit"]:focus {
  box-shadow: 0 0 0 3px #a0d1fa;
  outline: none;
  transition: 0.25s all ease;
}
.Login .Login--inner form .Submit__Form input[type="submit"]:hover {
  transition: 0.25s all ease;
  background: #272727;
  cursor: pointer;
}
.Login .Login--inner form .Submit__Form input[type="submit"].true {
  background: #000;
  color: #000;
}
.Login .Login--inner form .Submit__Form .loader {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Login .Login--inner .SignUp {
  display: inline-flex;
  align-items: center;
  padding: 0 10%;
  width: 100%;
  flex-direction: column;
}
.Login .Login--inner .SignUp h5 {
  margin: 0;
  color: #535353;
  margin-bottom: 0.25rem;
  font-weight: 400;
  font-size: 1rem;
}
.Login .Login--inner .SignUp a {
  color: #00b27e;
  text-decoration: none;
  font-weight: 500;
  padding: 2px;
  transition: 0.25s all ease;
}
.Login .Login--inner .SignUp a:hover,
.Login .Login--inner .SignUp a:focus {
  transition: 0.25s all ease;
  color: #07ce94;
}
.Login .Login--inner .Login__Error {
  opacity: 0;
  user-select: none;
}
.Login .Login--inner .Login__Error p {
  transition: 0.25s all ease;
  margin-top: 0;
  margin-bottom: 0.25rem;
  color: #d40000;
  font-weight: 500;
  font-size: 0.9rem;
}
.Login .Login--inner .Login__Error.true {
  opacity: 1;
  transition: 0.25s all ease;
  -webkit-animation-name: true;
  animation-name: true;
}
.Login .Login__Image {
  display: none;
}
@media (min-width: 768px) {
  .Login .Login--inner {
    align-items: flex-start;
  }
  .Login .Login--inner h2,
  .Login .Login--inner h4 {
    padding-left: 25%;
  }
  .Login .Login--inner h2 {
    font-size: 3rem;
  }
  .Login .Login--inner h4 {
    text-align: left;
  }
  .Login .Login--inner form {
    padding: 7.5% 25%;
  }
  .Login .Login--inner form input[type="submit"] {
    margin-right: auto;
  }
  .Login .Login--inner .SignUp {
    padding: 0 25%;
  }
}
@media (min-width: 992px) {
  .Login {
    margin-top: 0;
    max-height: calc(100vh - 134px);
  }
  .Login .Login--inner {
    margin-top: 6rem;
  }
  .Login .Login--inner form {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
  }
  .Login .Login--inner form input[type="submit"] {
    margin-left: unset;
  }
  .Login .Login--inner .SignUp {
    flex-direction: row;
  }
  .Login .Login--inner .SignUp h5 {
    margin: 0;
    margin-right: 0.25rem;
  }
  .Login .Login__Image {
    display: block;
    width: 75%;
    position: relative;
  }
  .Login .Login__Image img:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
  .Login .Login__Image img:last-child {
    width: 100%;
    object-fit: cover;
    max-height: calc(100vh - 134px);
  }
}
@media (min-width: 1200px) {
  .Login .Login--inner {
    margin-top: 8rem;
  }
  .Login .Login--inner h2,
  .Login .Login--inner h4 {
    padding-left: 30%;
  }
  .Login .Login--inner form {
    padding: 2.5% 30%;
  }
  .Login .Login--inner .SignUp {
    padding: 0 30%;
  }
}
@media (min-width: 1400px) {
  .Login .Login--inner h2,
  .Login .Login--inner h4 {
    padding-left: 30%;
  }
  .Login .Login--inner form {
    padding: 2.5% 40% 2.5% 30%;
  }
  .Login .Login--inner .SignUp {
    padding: 0 40% 0 30%;
  }
}
.animated {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes true {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes true {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 16px auto;
  position: relative;
  font-size: 3px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.loader.false {
  display: none;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@supports (-ms-ime-align: auto) {
  .Login .Login--inner form .floating-label label {
    position: absolute !important;
    top: 0.75rem !important;
    font-size: 12px;
  }
}
