.Single_Company .Single_Company--wrapper {
  background: #fff;
  box-shadow: 0px 3px 27px 0px rgba(151, 180, 203, 0.16);
  border: 1px solid #eeeeee;
  padding: 1rem;
  border-radius: 7px;
}
.Single_Company .Single_Company--wrapper .Single_Company--header {
  display: inline-flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
  flex-direction: column;
  align-items: flex-start;
}
.Single_Company .Single_Company--wrapper .Single_Company--header img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  object-fit: contain;
  margin-right: 1rem;
  border-radius: 50%;
}
.Single_Company .Single_Company--wrapper .Single_Company--header h3 {
  margin: 0;
  color: #262a68;
  font-size: 1.25rem;
}
.Single_Company .Single_Company--wrapper .Single_Company--header div:first-child {
  display: inline-flex;
  align-items: center;
}
.Single_Company .Single_Company--wrapper .Single_Company--header div.s {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.5rem;
}
@media (min-width: 992px) {
  .Single_Company .Single_Company--wrapper .Single_Company--header {
    flex-direction: row;
    justify-content: space-between;
  }
}
.Single_Company .Single_Company--wrapper .Single_Company--content {
  margin: 1rem 0;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item {
  margin: 1rem 0;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item h4,
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item h5 {
  margin: 0;
  margin-bottom: 0.25rem;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item h4 {
  color: #000000;
  font-weight: 600;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item a {
  color: #000000;
  text-decoration: none;
  font-size: 14px;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item h5 {
  color: #050720;
  font-weight: 500;
  font-size: 14px;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block p {
  line-height: 1.6;
  color: #000000;
  margin: 1.5rem 0;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .Send_Message {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .Send_Message a {
  padding: 1rem 2rem;
  border: 2px solid #262a68;
  border-radius: 4px;
  color: #262a68;
  font-weight: 600;
  text-decoration: none;
  transition: 0.25s all ease;
}
.Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .Send_Message a:hover {
  transition: 0.25s all ease;
  background: #262a68;
  color: #fff;
}
@media screen and (min-width: 992px) {
  .Single_Company .Single_Company--wrapper .Single_Company--header img {
    width: 88px;
    min-width: 88px;
    height: 88px;
    margin-right: 1.5rem;
    object-fit: contain;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--header h3 {
    font-size: 1.5rem;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content {
    display: inline-flex;
    width: 100%;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block {
    width: 50%;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item {
    display: inline-flex;
    width: 100%;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item h4 {
    width: 250px;
    padding-right: 2.5rem;
    text-align: right;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item:nth-child(2),
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block .item:nth-child(4) {
    margin-bottom: 2.5rem;
  }
  .Single_Company .Single_Company--wrapper .Single_Company--content .Single_Company--content--block:last-child {
    padding-left: 1rem;
  }
}
@media screen and (min-width: 1400px) {
  .Card .Table .Table--inner .Table-content .Table-row .item:nth-child(even) h4:last-child {
    text-align: right;
  }
  .Card .Table .Table--inner .Table-content .Table-row .item:nth-child(even) h4:first-child {
    text-align: left;
  }
}
