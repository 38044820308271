.Form .Form--inner form .Select_File {
  display: inline-flex;
  width: 100%;
}
.Form .Form--inner form .Select_File h4 {
  color: #424242;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
}
.Form .Form--inner form .Select_File .dropzone {
  display: flex;
  justify-content: flex-start;
}
.Form .Form--inner form .Select_File .dropzone p {
  padding: 0.5rem 1rem;
  height: 42.5px;
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
  color: #b0b0b5;
  border: 1px solid #9aabc2;
  border-radius: 6px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Form .Form--inner form .Files ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
.Form .Form--inner form .Files ul li {
  padding: 2rem 0;
  list-style: none;
}
@media screen and (min-width: 992px) {
  .Form .Form--inner form .Select_File h4 {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    width: 50%;
  }
  .Form .Form--inner form .Select_File .dropzone p {
    width: auto;
  }
  .Form .Form--inner form .Files ul {
    justify-content: unset;
    margin-left: 157px;
  }
}
