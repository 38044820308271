.Company_Info .Company_Info-block {
  padding-right: 2rem;
  margin-bottom: 2rem;
}
.Company_Info .Company_Info-block .item {
  margin: 1rem 0;
  display: inline-flex;
  width: 100%;
}
.Company_Info .Company_Info-block .item h4,
.Company_Info .Company_Info-block .item h5 {
  margin: 0;
}
.Company_Info .Company_Info-block .item h4 {
  color: #686a7d;
  font-size: 14px;
  font-weight: 400;
  width: 150px;
  text-align: right;
  padding-right: 1rem;
}
.Company_Info .Company_Info-block .item h5 {
  color: #050720;
  font-size: 14px;
}
.Company_Info .Company_Info-block .item.item-space {
  margin-top: 3rem;
}
.Company_Info .Company_Info-block p {
  color: #000000;
  font-size: 14px;
  line-height: 1.6;
}
.Add_Truck {
  margin: 1rem 0;
}
.Add_Truck button {
  border: none;
  background: none;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #15198f;
  font-size: 1rem;
  padding: 0;
}
.Add_Truck button:hover {
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .Screen_MyCompany .Card .Progress-header {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .Screen_MyCompany .Card .Progress-header img {
    margin-right: 1rem;
  }
  .Screen_MyCompany .Card .Progress-header h3 {
    padding-right: 4rem;
  }
  .Company_Info {
    display: inline-flex;
    width: 100%;
  }
  .Company_Info .Company_Info-block {
    width: 50%;
  }
  .Company_Info .Company_Info-block:first-child {
    margin-right: 2rem;
    border-right: 1px solid #eeeeee;
  }
}
