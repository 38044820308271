.Table {
  display: flex;
}
.Table .Table--inner {
  width: 100%;
}
.Table .Table--inner .Table-head {
  padding: 1rem 0;
  width: 100%;
}
.Table .Table--inner .Table-head h4 {
  margin: 0;
  color: #686a7d;
  font-size: 14px;
  font-weight: 400;
}
.Table .Table--inner .Table-head.only-desktop {
  display: none !important;
}
.Table .Table--inner .Table-content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.Table .Table--inner .Table-content .Table-row {
  padding: 1rem;
  margin: 2rem 0;
  position: relative;
  background: #fff;
  border-radius: 7px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 3px 30px 0px rgba(151, 180, 203, 0.16);
}
.Table .Table--inner .Table-content .Table-row .item {
  padding: 0.75rem 0;
}
.Table .Table--inner .Table-content .Table-row .item:first-child {
  padding-top: 1.5rem;
}
.Table .Table--inner .Table-content .Table-row .item h4,
.Table .Table--inner .Table-content .Table-row .item h5,
.Table .Table--inner .Table-content .Table-row .item h6 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  width: 100%;
}
.Table .Table--inner .Table-content .Table-row .item h4,
.Table .Table--inner .Table-content .Table-row .item a {
  font-size: 1rem;
  font-weight: 500;
  color: #010101;
}
.Table .Table--inner .Table-content .Table-row .item a {
  text-decoration: none;
}
.Table .Table--inner .Table-content .Table-row .item h5 {
  color: #686a7d;
}
.Table .Table--inner .Table-content .Table-row .item h6.only-mobile {
  font-size: 12px;
  color: #686a7d;
  text-transform: uppercase;
}
.Table .Table--inner .Table-content .Table-row .item .options {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  width: 35px;
  display: flex;
  justify-content: center;
  user-select: none;
}
.Table .Table--inner .Table-content .Table-row .item .options:hover {
  cursor: pointer;
}
.Table .Table--inner .Table-content .Table-row .item .options img {
  pointer-events: none;
}
.Table .Table--inner .Table-content .Table-row .item .options.false .options-dropdown {
  display: none;
}
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown {
  position: absolute;
  top: 1.5rem;
  right: 0;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.16);
  background: #fff;
}
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown ul li,
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown ul a {
  list-style-type: none;
  padding: 1rem 2rem;
  white-space: nowrap;
}
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown ul li:hover,
.Table .Table--inner .Table-content .Table-row .item .options .options-dropdown ul a:hover {
  background: #f9f9f9;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status {
  color: #fff;
  width: max-content;
  height: 25px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.75rem;
  padding-left: 1.75rem;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  user-select: none;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 0.5rem;
  top: 0.5rem;
  border-radius: 50%;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status.active {
  background: #3ad59b;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status.active:before {
  background: #27a590;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status.inactive {
  background: #f9f9f9;
  color: #000;
}
.Table .Table--inner .Table-content .Table-row .item .toggler-status.inactive:before {
  background: #eaeaea;
}
.Table .Table--inner .Table-content .Table-row .item .toggler {
  color: #686a7d;
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.Table .Table--inner .Table-content .Table-row .item .toggler .toggler-button {
  margin-right: 1rem;
  width: 50px;
  height: 25px;
  background: #b2b2b2;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.Table .Table--inner .Table-content .Table-row .item .toggler .toggler-button:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #b2b2b2;
  transition: 0.25s all ease;
}
.Table .Table--inner .Table-content .Table-row .item .toggler input {
  width: 50px;
  height: 25px;
  margin: 0;
  position: absolute;
  z-index: 1;
  opacity: 0;
}
.Table .Table--inner .Table-content .Table-row .item .toggler input:checked ~ .toggler-button {
  background: #2bbf8d;
}
.Table .Table--inner .Table-content .Table-row .item .toggler input:checked ~ .toggler-button:before {
  transform: translateX(100%);
  left: unset;
  transition: 0.25s all ease;
}
.Table .Table--inner .Table-content .Table-row .item .toggler input:hover {
  cursor: pointer;
}
hr {
  border: none;
  height: 1px;
  background: #dcdcdc;
}
.Table_Modal--close {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 19;
  background: rgba(0, 0, 0, 0.4);
}
.Table_Modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.16);
  padding: 1rem;
  z-index: 20;
  pointer-events: none;
  display: flex;
  margin: auto;
}
.Table_Modal .Table_Modal--inner {
  position: relative;
  pointer-events: all;
  width: 100%;
  max-height: 100%;
  background: #fff;
  overflow-y: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.Table_Modal .Table_Modal--inner .Table_Modal--header {
  padding: 1rem;
  display: flex;
  width: 100%;
  background: #f9f9f9;
  justify-content: center;
  align-items: center;
}
.Table_Modal .Table_Modal--inner .Table_Modal--header h2 {
  font-size: 1rem;
  color: #262a68;
  text-align: center;
  line-height: 1.6;
  padding-right: 3.5rem;
  margin: 0;
  font-weight: 600;
}
.Table_Modal .Table_Modal--inner .Table_Modal--header .Table_Modal--inner-close {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.25s all ease;
  border: 1px solid #eaeaea;
}
.Table_Modal .Table_Modal--inner .Table_Modal--header .Table_Modal--inner-close:hover {
  cursor: pointer;
  background: #eaeaea;
  transition: 0.25s all ease;
}
.Table_Modal .Table_Modal--inner .Table_Modal--body {
  padding: 2rem 1rem;
}
.Table_Modal .Table_Modal--inner .Table_Modal--body img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem auto;
  max-height: 25vh;
}
.Table_Modal .Table_Modal--inner .Table_Modal--body h3,
.Table_Modal .Table_Modal--inner .Table_Modal--body h4 {
  color: #050720;
  margin: 0;
  text-align: center;
  line-height: 1.6;
}
.Table_Modal .Table_Modal--inner .Table_Modal--body h3 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.6;
}
.Table_Modal .Table_Modal--inner .Table_Modal--body h4 {
  font-size: 14px;
  font-weight: 400;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer {
  padding: 1rem;
  background: #f9f9f9;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button {
  background: none;
  border: none;
  color: #050720;
  font-family: "Inter", sans-serif;
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  font-weight: 600;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button:first-child {
  position: relative;
  padding: 0;
  margin: 1rem 2rem;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button:first-child:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #050720;
  height: 2px;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button:last-child {
  background: #3ad59b;
  color: #fff;
}
.Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button:hover {
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .Table_Modal {
    display: inline-flex;
    width: 100%;
    padding: 2.5rem 4rem;
  }
  .Table_Modal .Table_Modal--inner {
    max-width: 992px;
  }
}
@media screen and (min-width: 1400px) {
  .Table {
    max-width: 100%;
  }
  .Table .Table--inner .Table-head {
    display: flex;
  }
  .Table .Table--inner .Table-head h4 {
    text-transform: uppercase;
    font-size: 13px;
    flex: 0.5 0.5 0;
  }
  .Table .Table--inner .Table-head h4:nth-child(1) {
    flex: 1 1 0;
  }
  .Table .Table--inner .Table-head h4:last-child {
    text-align: right;
  }
  .Table .Table--inner .Table-head h4:first-child {
    text-align: left;
  }
  .Table .Table--inner .Table-head.only-desktop {
    display: inline-flex !important;
  }
  .Table .Table--inner .Table-content .Table-row {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 1rem 0 !important;
    margin: 0;
    border: none;
    box-shadow: none;
  }
  .Table .Table--inner .Table-content .Table-row .item {
    display: flex;
    flex: 0.5 0.5 0;
    font-size: 14px;
    flex-direction: column;
    width: 0;
    padding: 0;
    background: unset;
  }
  .Table .Table--inner .Table-content .Table-row .item:first-child {
    padding: 0;
  }
  .Table .Table--inner .Table-content .Table-row .item:nth-child(1) {
    flex: 1 1 0;
  }
  .Table .Table--inner .Table-content .Table-row .item h6.only-mobile {
    display: none;
  }
  .Table .Table--inner .Table-content .Table-row .item h4,
  .Table .Table--inner .Table-content .Table-row .item h5,
  .Table .Table--inner .Table-content .Table-row .item h6 {
    margin: 0;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 2rem;
  }
  .Table .Table--inner .Table-content .Table-row .item h4 {
    font-size: 1rem;
  }
  .Table .Table--inner .Table-content .Table-row .item h4:last-child {
    text-align: left !important;
  }
  .Table .Table--inner .Table-content .Table-row .item h5 {
    color: #686a7d;
  }
  .Table .Table--inner .Table-content .Table-row .item:last-child {
    align-items: flex-end;
  }
  .Table .Table--inner .Table-content .Table-row .item:first-child {
    align-items: flex-start;
  }
  .Table .Table--inner .Table-content .Table-row .item .options {
    position: relative;
    top: unset;
  }
  .Table .Table--inner .Table-content .Table-row .item .options .options-dropdown {
    z-index: 1;
  }
  .Table_Modal {
    display: inline-flex;
    width: 100%;
    padding: 5rem 8rem;
  }
  .Table_Modal .Table_Modal--inner {
    display: inline-flex;
    width: 100%;
    margin: auto;
    max-width: 1140px;
  }
  .Table_Modal .Table_Modal--inner h3,
  .Table_Modal .Table_Modal--inner h4 {
    margin-bottom: 0.25rem;
  }
  .Table_Modal .Table_Modal--inner .Table_Modal--header h2 {
    padding: 0;
  }
  .Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons {
    width: 100%;
    justify-content: center;
  }
  .Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button {
    padding: 1rem 2rem;
    margin: 0 1rem;
    transition: 0.25s all ease;
  }
  .Table_Modal .Table_Modal--inner .Table_Modal--footer .Table_Modal--footer--buttons button:last-child:hover {
    transition: 0.25s all ease;
    background: #41e9ab;
  }
}
@media screen and (min-width: 1600px) {
  .Table {
    padding: 1rem;
  }
}
