.Card.Progress {
  position: relative;
}
.Progress-header h3 {
  font-weight: 700;
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.6;
}
.Progress-header h3 span {
  color: #3990b7;
}
.Progress-header a {
  width: 200px;
  color: #707184;
  padding: 1.25rem 0.5rem;
  border: 1px solid #707184;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  transition: 0.25s all ease;
}
.Progress-header a:hover {
  background: #707184;
  color: #fff;
  transition: 0.25s all ease;
}
.progress-bar {
  display: inline-flex;
  width: 100%;
}
.progress-bar h6 {
  transform: translateY(10px);
  margin: 0;
  padding: 0 0.25rem;
  text-align: right;
  white-space: nowrap;
  color: #8a8a8a;
  font-weight: 500;
}
.progress-bar h6:first-child {
  width: 56%;
}
.progress-bar h6:nth-child(2) {
  width: 25%;
}
.progress-bar h6:nth-child(3) {
  width: 25%;
  transform: translate(20px, 10px);
}
.progress-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: #d2d2d2;
}
.progress-line:before {
  content: "";
  position: absolute;
  left: 0;
  height: 8px;
  background: #00b27e;
}
.progress-line.one:before {
  width: 50%;
}
.progress-line.two:before {
  width: 75%;
}
@media screen and (min-width: 992px) {
  .Progress-header {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .Progress-header img {
    margin-right: 1rem;
  }
  .Progress-header h3 {
    padding-right: 4rem;
  }
}
