.Sub_Menu {
  display: flex;
  align-items: center;
  background: #fbfbfc;
  margin-top: 64px;
  left: -1rem;
  position: relative;
  width: calc(100% + 2rem);
}
.Sub_Menu .Sub_Menu--inner {
  display: inline-flex;
  width: 100%;
  height: 60px;
  padding-top: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
}
.Sub_Menu .Sub_Menu--inner a {
  white-space: nowrap;
  padding: 0 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0 1.25rem;
  text-decoration: none;
  position: relative;
  color: #050720;
  font-weight: 500;
}
.Sub_Menu .Sub_Menu--inner a:hover {
  color: #7d7f90;
}
.Sub_Menu .Sub_Menu--inner a.active {
  color: #050720;
}
.Sub_Menu .Sub_Menu--inner a.active:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: #e13505;
}
.Sub_Menu .Sub_Menu--inner::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
@media screen and (min-width: 992px) {
  .Sub_Menu {
    background: none;
    margin-top: 0;
    left: unset;
    width: 100%;
  }
  .Sub_Menu .Sub_Menu--inner {
    justify-content: center;
    align-items: center;
    max-width: 795px;
    background: #fff;
    box-shadow: 0px 3px 27px 0px rgba(151, 180, 203, 0.16);
    border: 1px solid #eeeeee;
    margin: auto;
    border-radius: 10px;
    padding: 3rem 0 2.5rem;
  }
  .Sub_Menu .Sub_Menu--inner a:not(:last-child):after {
    content: "";
    position: absolute;
    right: -20px;
    top: 0;
    height: calc(100% - 0.5rem);
    width: 1.5px;
    background: #707070;
  }
}
@media screen and (min-width: 1200px) {
  .Sub_Menu .Sub_Menu--inner {
    max-width: 650px;
  }
}
@media screen and (min-width: 1400px) {
  .Sub_Menu .Sub_Menu--inner {
    max-width: 795px;
  }
}
@media screen and (min-width: 1600px) {
  .Sub_Menu .Sub_Menu--inner {
    max-width: 970px;
  }
}
@media screen and (min-width: 1800px) {
  .Sub_Menu .Sub_Menu--inner {
    max-width: 1170px;
  }
}
