.Checkout {
  height: 100vh;
}
.Checkout .Checkout__Navbar header {
  height: 60px;
  display: flex;
  align-items: center;
}
.Checkout .Checkout__Navbar header .logo {
  display: flex;
  width: 120px;
}
.Checkout .Checkout__Navbar header .logo img {
  max-width: 100%;
  height: auto;
}
.Checkout .Checkout__Steps {
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 1.5rem 0;
}
.Checkout .Checkout__Steps a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}
.Checkout .Checkout__Steps .Step {
  margin: 0 0.5rem;
}
.Checkout .Checkout__Steps .Step h4 {
  margin: 0;
  color: #0f1540;
  font-weight: 500;
}
.Checkout .Checkout__Steps .Step.completed h4 {
  color: #00b27e;
}
.Checkout .Checkout__Steps .Step.not-completed h4 {
  color: #a8a8a8;
}
.Checkout .Checkout__Steps .Step.step-current h4 {
  font-weight: 600;
}
.Checkout .Checkout__Steps .Checkout--da {
  position: absolute;
  right: 0;
}
.Checkout .Checkout__Steps .Step--arrow.not-completed svg g line,
.Checkout .Checkout__Steps .Step--arrow.not-completed svg g path {
  stroke: #a8a8a8;
}
.Checkout .Content {
  padding-bottom: 250px;
}
.Checkout .Content .Summary {
  background: #ffffff;
  background: -moz-linear-gradient(0deg, #ffffff 0%, #f4f8fc 100%);
  background: -webkit-linear-gradient(0deg, #ffffff 0%, #f4f8fc 100%);
  background: linear-gradient(0deg, #ffffff 0%, #f4f8fc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f4f8fc", GradientType=1);
}
.Checkout .Content .Summary .Summary--inner {
  padding: 2rem 1rem;
}
.Checkout .Content .Summary .Summary--inner .Back a {
  color: #262a68;
  text-decoration: none;
  font-size: 14px;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  border: 1px solid #262a68;
  font-weight: 400;
  transition: 0.25s all ease;
}
.Checkout .Content .Summary .Summary--inner .Back a:hover {
  transition: 0.25s all ease;
  background: #262a68;
  color: #fff;
}
.Checkout .Content .Summary .Summary--inner h3 {
  margin-bottom: 2rem;
  color: #050720;
  font-size: 1.25rem;
}
.Checkout .Content .Summary .Summary--inner .Summary--list h5 {
  color: #050720;
  font-weight: 500;
  font-size: 1rem;
  border-bottom: 1px solid #050720;
  padding-bottom: 0.25rem;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item {
  display: inline-flex;
  width: 100%;
  padding: 0.5rem 0;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item h4,
.Checkout .Content .Summary .Summary--inner .Summary--list .Item h5 {
  margin: 0;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item h5 {
  border-bottom: none;
  white-space: nowrap;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item h4 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #0f1540;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item.true {
  position: relative;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Item.true span {
  color: #a8a8a8;
  text-decoration: line-through;
  padding-right: 0.5rem;
  font-size: 14px;
}
.Checkout .Content .Summary .Summary--inner .Summary--list .Total-wrapper {
  border-top: 1px solid #050720;
}
.Checkout .Content .Summary .Summary--ds {
  position: absolute;
  left: 0;
  bottom: 15%;
}
.Checkout .Content .Billing {
  padding: 0 15px;
  max-width: 600px;
}
.Checkout .Content .Billing h2 {
  color: #262a68;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1rem;
}
.Checkout .Content .Billing form#checkoutForm .floating-label {
  margin: 0.5rem 0;
}
.Checkout .Content .Billing form .floating-label {
  width: 100%;
  position: relative;
}
.Checkout .Content .Billing form .floating-label input {
  display: flex;
  width: 100%;
  padding: 1rem;
  padding-top: 1.35rem;
  font-family: "Inter", sans-serif;
  background: #fff;
  border: 1px solid #ececec;
  font-size: 1rem;
  color: #000;
  transition: 0.25s all ease;
}
.Checkout .Content .Billing form .floating-label input:focus ~ label,
.Checkout .Content .Billing form .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.2rem;
  left: 1rem;
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Checkout .Content .Billing form .floating-label input:focus {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.Checkout .Content .Billing form .floating-label label {
  color: #9a9a9a;
  transition: 0.25s all ease;
  position: absolute;
  left: 1rem;
  top: 1.25rem;
  pointer-events: none;
}
.Checkout .Content .Billing form .inline-label {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.Checkout .Content .Billing form .inline-label .floating-label {
  width: auto;
}
.Checkout .Content .Billing form .inline-label .floating-label label {
  top: 1.2rem;
}
.Checkout .Content .Billing form .inline-label .floating-label:first-child {
  margin-right: 0.5rem;
}
.Checkout .Content .Billing form .inline-label .floating-label input:focus ~ label,
.Checkout .Content .Billing form .inline-label .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.7rem;
}
.Checkout .Content .Billing form .checkboxes {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
}
.Checkout .Content .Billing form .checkboxes label {
  margin: 0.5rem 0;
  cursor: pointer;
  line-height: 1.5;
}
.Checkout .Content .Billing form .checkboxes input[type="checkbox"] {
  position: relative;
  transform: translateX(-4px);
}
.Checkout .Content .Billing form .checkboxes input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 15.5px;
  height: 15px;
  top: 0;
  left: 0;
  border: 1.5px solid #000;
  border-radius: 3px;
  background-color: #fff;
}
.Checkout .Content .Billing form .checkboxes input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1.5px;
  left: 5.25px;
}
.Checkout .Content .Billing form .Submit {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
  position: relative;
}
.Checkout .Content .Billing form .Submit input {
  border: none;
  color: #fff;
  font-family: "Inter", sans-serif;
  padding: 1rem 3rem;
  background: #00b27e;
  cursor: pointer;
  border-radius: 3px;
  font-size: 1rem;
  transition: 0.25s all ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.Checkout .Content .Billing form .Submit input:hover {
  transition: 0.25s all ease;
  background: #07ce94;
}
.Checkout .Content .Billing form .Submit input.true {
  color: #00b27e;
}
.Checkout .Content .Billing form .Submit input.true:hover {
  color: #07ce94;
}
.Checkout .Content .Billing .Discount .floating-label.valid input {
  border-color: #07ce94;
}
.Checkout .Content .Billing .Discount .floating-label.invalid input {
  border-color: #e13505;
}
.Checkout .Content .Billing .Discount form {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}
.Checkout .Content .Billing .Discount form .Discount__Apply {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  position: relative;
}
.Checkout .Content .Billing .Discount form .Discount__Apply input[type="submit"] {
  margin: 0;
  margin-left: 0.5rem;
  padding: 1.25rem 3rem;
  font-family: "Inter", sans-serif;
  border: none;
  background: #fff;
  border: 1px solid #262a68;
  border-radius: 3px;
  color: #262a68;
  font-weight: 500;
  transition: 0.25s all ease;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.Checkout .Content .Billing .Discount form .Discount__Apply input[type="submit"]:hover {
  transition: 0.25s all ease;
  background: #262a68;
  color: #fff;
}
.Checkout .Content .Billing .Discount form .Discount__Apply.true input[type="submit"] {
  color: #fff;
}
.Checkout .Content .Billing .Discount form .Discount__Apply.true input[type="submit"]:hover {
  color: #262a68;
}
.Checkout .Content .Billing .Discount form .Discount__Apply.true input[type="submit"]:not(:hover) ~ .loader {
  color: #262a68;
  background: #262a68;
}
.Checkout .Content .Billing .Discount form .Discount__Apply.true input[type="submit"]:not(:hover) ~ .loader:before,
.Checkout .Content .Billing .Discount form .Discount__Apply.true input[type="submit"]:not(:hover) ~ .loader:after {
  background: #262a68;
}
@media screen and (max-width: 772px) {
  .Checkout .Content .Billing form .inline-label {
    display: block;
  }
}
@media (min-width: 578px) {
  .Checkout .Content .Billing .Discount form {
    width: 100%;
    display: inline-flex;
  }
  .Checkout .Content .Billing .Discount form .floating-label label {
    top: 1.7rem;
  }
  .Checkout .Content .Billing .Discount form .floating-label input {
    margin: 0.5rem 0;
  }
  .Checkout .Content .Billing .Discount form .floating-label input:focus ~ label,
  .Checkout .Content .Billing .Discount form .floating-label input:not(:placeholder-shown) ~ label {
    top: 0.7rem;
  }
  .Checkout .Content .Billing .Discount form input[type="submit"] {
    margin: 0.5rem 0 0.5rem 0.5rem;
    padding: 1rem 3rem;
    font-family: "Inter", sans-serif;
    -webkit-appearance: none;
  }
  #cvv {
    margin-right: 0.5rem;
  }
}
@media (min-width: 992px) {
  .Checkout .Checkout__Navbar header {
    height: 130px;
  }
  .Checkout .Checkout__Navbar header .logo {
    width: 240px;
  }
  .Checkout .Checkout__Steps {
    margin: 0;
    padding-left: 15%;
    height: 70px;
  }
  .Checkout .Content {
    padding-bottom: 0;
    display: inline-flex;
    width: 100%;
    height: calc(100vh - 200px);
  }
  .Checkout .Content .Summary {
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .Checkout .Content .Summary .Summary--inner {
    padding: 3rem;
  }
  .Checkout .Content .Billing {
    padding: 1rem 4rem 3rem 4rem;
  }
  .Checkout .Content .Billing form {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .Checkout .Content .Summary .Summary--inner {
    width: 400px;
  }
}
@media (max-width: 1200px) {
  .Checkout .Content .Summary .Summary--inner {
    max-width: 350px;
  }
}
@media (max-width: 992px) and (min-width: 578px) {
  .Checkout .Content .Summary .Summary--inner {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    padding: 3rem;
  }
  .Checkout .Content .Billing {
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 380px) {
  .Checkout .Checkout__Steps .Step h4 {
    font-size: 12px;
  }
}
.Payment__Error {
  opacity: 0;
  user-select: none;
}
.Payment__Error p {
  display: none;
  transition: 0.25s all ease;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #d40000;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.5;
}
.Payment__Error.true {
  opacity: 1;
  transition: 0.25s all ease;
  -webkit-animation-name: true;
  animation-name: true;
}
.Payment__Error.true p {
  display: block;
}
.Success h3 {
  color: #262a68;
  padding-bottom: 1rem;
  border-bottom: 0.5px solid #dedede;
  font-size: 1.5rem;
  line-height: 1.5;
}
.Success h4 {
  color: #0f1540;
  line-height: 1.5;
}
.Success ul {
  margin: 0;
  padding: 0;
}
.Success ul li {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0.5rem 0;
}
.Success ul li svg {
  margin-right: 0.5rem;
}
.Success a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  background: #00b27e;
  padding: 1rem 3rem;
  border-radius: 3px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: max-content;
  margin-top: 3rem;
  transition: all 0.25s ease;
}
.Success a:hover {
  transition: all 0.25s ease;
  background: #07ce94;
}
.animated {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes true {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes true {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 16px auto;
  position: relative;
  font-size: 3px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -15%);
  display: block;
}
.loader.false {
  display: none;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
#card-number,
#cvv,
#expiration-date {
  border: 1px solid #ececec;
  height: 58.8px;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  background: #fff;
}
.braintree-hosted-fields-focused {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.braintree-hosted-fields-focused ~ label {
  transform: translateY(-1rem);
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
#credit-card-number:not(:placeholder-shown) ~ label {
  top: 0.2rem;
  left: 1rem;
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.floating-label.not-empty label {
  transform: translateY(-1rem);
  font-size: 12px;
  transition: 0.25s all ease;
}
