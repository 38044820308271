body,
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

.DateRangePicker_picker__portal {
  z-index: 12 !important;
}
.DateRangePicker_picker__portal .CalendarDay__selected,
.DateRangePicker_picker__portal .CalendarDay__selected:active,
.DateRangePicker_picker__portal .CalendarDay__selected:hover {
  background: #15198f;
  border: 1px double #15198f;
}
.DateRangePicker_picker__portal .CalendarDay__selected_span {
  background: #4785ff;
  border: 1px double #3a7cff;
}
.DateRangePicker_picker__portal .CalendarDay__hovered_span,
.DateRangePicker_picker__portal .CalendarDay__hovered_span:active,
.DateRangePicker_picker__portal .CalendarDay__hovered_span:hover {
  background: #4785ff;
  border: 1px double #3a7cff;
  color: #fff;
}

@media only screen and (max-width: 992px) {
  #purechat-container {
    display: none;
  }
}
