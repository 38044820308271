#card-number,
#cvv,
#expiration-date {
  height: 42.5px;
  border: 1px solid #9aabc2;
  border-radius: 6px;
  padding: 0.5rem;
}
#card-number.braintree-hosted-fields-invalid,
#cvv.braintree-hosted-fields-invalid,
#expiration-date.braintree-hosted-fields-invalid {
  border: 1px solid #e13505;
}
#card-number.braintree-hosted-fields-valid,
#cvv.braintree-hosted-fields-valid,
#expiration-date.braintree-hosted-fields-valid {
  color: #3ad59b;
  border: 1px solid #3ad59b;
}
#cardDetailsForm input::placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 300;
}
